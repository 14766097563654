// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #4a4a4a;
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');

// Import Fonts
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap');
@import url('https://use.typekit.net/ufa3blf.css');

// Font Awesome
// @import '~@fortawesome/fontawesome-free/scss/fontawesome';

// Victory fonts.
$fonts-default: 'source-sans-pro', Arial, sans-serif;
$font-sourcesans: 'source-sans-pro', Arial, sans-serif;
$font-sourcesans-condensed: 'source-sans-pro', Arial, sans-serif;
$fa: 'FontAwesome';
$font-engschrift: 'din-1451-lt-pro-engschrift';

$fonts-din-engschrift: 'din-1451-lt-pro-engschrift', Arial, sans-serif;
$font-engschrift-pro: 'din-1451-lt-pro-engschrift', Arial, sans-serif;

// Font weights
$font-weight-regular: 400;
$font-weight-semi-bold: 600;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Default breakpoints
$tablet: 768px;
$desktop: 1260px;

// Colour definitions.
// http://chir.ag/projects/name-that-color
// e.g., $color-green-key_lime_pie: #ABC123;

// Blue.
$color-blue-astral: #3477b1;
$color-blue-bahama: #00609a;
$color-blue-botticelli: #ccdfea;
$color-blue-cornflower: #5bc2e7;
$color-blue-cello: #000000;
$color-blue-denim: #0f6bad;
$color-blue-elephant: #123955;
$color-aubergine: #1e1248;
$color-blue-loblolly: #b7b7b7;
$color-blue-lochmara: #0078c1;
$color-blue-venice_blue: #0c5f97;
$color-blue-magenta: #363492;
$color-blue-cyan: #0166cc;
$color-melon: #ed6B5e;

// Black.
$color-black: #000000;
$color-black-mine_shaft: #262626;

// Grey.
$color-grey-alto: #d8d8d8;
$color-grey-boulder: #777777;
$color-grey-dove: #717171;
$color-grey-dusty_gray:#494949;
$color-grey-gallery: #eeeeee;
$color-grey-gull: #a1b0bb;
$color-grey-silver_chalice: #b7b7b7;
$color-grey-tundora: #444444;
$color-grey-dove: #494949;
$color-grey-suva: #929292;
$color-grey-whisper: #EDEDED;
$color-grey-light: #6e6e6e;


// Orange.
$color-orange-yellow_orange: #feb533;
$color-tango: #F79554;

// Red.
$color-red: #cc1122;
$color-red-strong_red: #ec6558;
$terracotta: #a94442;
$soft-peach: #f2dede;

// White.
$color-white: #ffffff;
$color-white-alabaster: #f7f7f7;

// Yellow.
$color-yellow-grandis: #fed76f;

// Green
$color-green: #008000;
$color-grape: #B49AD2;

// VU - Centenary.
$color-branding-centenary-bg: #262626;

// VicPoly.
$color-branding-vicpoly-bg: #1da593;
$color-branding-vicpoly-title-stripe: #055b64;
$color-branding-vicpoly-active: darken($color-branding-vicpoly-title-stripe, 3%);

// Components colours.
// Generic colours.
$color-primary-background: $color-grey-gallery;

// Hover state colours.
$color-primary-font-hover-state: $color-blue-elephant;
$color-primary-hover-state: $color-yellow-grandis;

// Charcoal
$color-charcoal: #494949;

// New Brand
$color-grandis: #FDD06D;
$color-new-blue: #5bc2e7;

// Background colours.
// $backgrounds: (
//   blue: $color-blue-lochmara, 
//   wild_sand: $color-white-alabaster,
// );
// @each $label, $color in $backgrounds {
//   .background--#{$label} {
//     background-color: $color;
//     position: relative;
//     z-index: 0;
//   }
// }

// Generic components for background colours.

// .background--blue {
//   .btn {
//     a {
//       border: {
//         color: transparent;
//         style: solid;
//         width: 1.5px;
//       }
//       color: $color-black-mine_shaft;
//       display: block;
//       height: 100%;
//       outline: 0;
//       padding: 10px;
//       text-decoration: none;
//       width: 100%;

//       &:focus {
//         // @include box-shadow($color-white 0 0 15px);
//         box-shadow: $color-white 0 0 15px;
//         border-color: $color-white;
//         outline: 15px;
//       }
//     }
//   }
// }
