.nuxt-progress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    width: 0;
    opacity: 1;
    transition: width .1s, opacity .4s;
    background-color: #000;
    z-index: 999999
}

.nuxt-progress.nuxt-progress-notransition {
    transition: none
}

.nuxt-progress-failed {
    background-color: red
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.h1,
.h2,
.h3,
.h4,
.hero-title,
.vu-button,
h1,
h2,
h3,
h4 {
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
    text-transform: uppercase
}

.h5,
.h6,
h5,
h6 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: none
}

.hero-title {
    margin: 1.5rem 0;
    font-size: 2.125rem;
    line-height: 2.75rem
}

@media screen and (min-width:992px) {
    .hero-title {
        margin: 2rem 0;
        font-size: 5rem;
        line-height: 5.5rem
    }
}

.h1,
h1 {
    margin: 1.5rem 0;
    font-size: 2.125rem;
    line-height: 2.75rem
}

@media screen and (min-width:992px) {
    .h1,
    h1 {
        font-size: 3.5rem;
        line-height: 4rem
    }
}

.h2,
h2 {
    margin: 1rem 0;
    font-size: 1.75rem;
    line-height: 2.25rem
}

@media screen and (min-width:992px) {
    .h2,
    h2 {
        margin: 1.5rem 0;
        font-size: 2.75rem;
        line-height: 3.25rem
    }
}

.h3,
h3 {
    margin: 1rem 0;
    font-size: 1.5rem;
    line-height: 2rem
}

@media screen and (min-width:992px) {
    .h3,
    h3 {
        font-size: 2rem;
        line-height: 2.5rem
    }
}

.h4,
h4 {
    margin: .5rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .h4,
    h4 {
        margin: 1rem 0;
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.h5,
h5 {
    margin: .25rem 0;
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .h5,
    h5 {
        margin: .5rem 0;
        font-size: 1.25rem
    }
}

.h6,
h6 {
    margin: .25rem 0;
    font-size: 1rem;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .h6,
    h6 {
        margin: .5rem 0;
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.paragraph--lead,
.txt-l,
blockquote {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .paragraph--lead,
    .txt-l,
    blockquote {
        font-size: 1.25rem
    }
}

.txt-m,
ol,
p,
ul {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .txt-m,
    ol,
    p,
    ul {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.txt-s,
figcaption {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem
}

@media screen and (min-width:992px) {
    .txt-s,
    figcaption {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

.txt-xs,
small {
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.25rem
}

@media screen and (min-width:992px) {
    .txt-xs,
    small {
        font-size: .875rem;
        line-height: 1.25rem
    }
}

:root {
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4
}

html {
    line-height: 1.15;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

details,
main {
    display: block
}

hr {
    height: 0
}

code,
kbd,
pre,
samp {
    font-family: ui-monospace, SFMono-Regular, Consolas, "Liberation Mono", Menlo, monospace;
    font-size: 1em
}

sub,
sup {
    position: relative;
    vertical-align: baseline;
    font-size: 75%;
    line-height: 0
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: 1.15
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

progress {
    vertical-align: baseline
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

summary {
    display: list-item
}

template {
    display: none
}

button:focus {
    outline: 2px solid #0c71ac;
    outline-offset: -2px
}

button:focus:not(.focus-visible),
button:focus:not(:focus-visible) {
    outline: none
}

*,
:after,
:before {
    box-sizing: border-box
}

body {
    overflow-x: hidden;
    font-family: "Source Sans Pro", sans-serif;
    color: #262626
}

blockquote {
    position: relative;
    margin: .5rem 0;
    padding: 0 2rem;
    font-weight: 600;
    text-indent: 2rem
}

blockquote:before {
    position: absolute;
    top: 0;
    display: block;
    width: 2rem;
    font-size: 3.0625rem;
    font-weight: 600;
    text-indent: 0;
    color: #5bc2e7;
    content: "\201C"
}

@media screen and (min-width:768px) {
    blockquote:before {
        font-size: 3.5rem
    }
}

blockquote>p {
    margin: .5rem 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit
}

@media screen and (min-width:992px) {
    blockquote {
        padding: 0 4rem
    }
}

figcaption {
    padding: 1.25rem .5rem;
    background: #f7f7f7;
    color: #6e6e6e
}

@media screen and (min-width:992px) {
    figcaption {
        padding: .5rem 1rem .75rem
    }
}

figure {
    margin: 1.5rem 0
}

figure,
figure img {
    display: block;
    width: 100%;
    height: auto
}

@media screen and (min-width:992px) {
    figure {
        margin: 2rem 0 1.5rem
    }
}

hr {
    border: 0;
    height: 1px;
    background: #b7b7b7
}

li {
    margin: .25rem 0
}

@media screen and (max-width:991px) {
    li {
        margin: 1rem 0
    }
    li+li {
        margin-top: 2rem
    }
}

ol,
ul {
    padding: 0 0 0 1.75rem
}

a {
    color: #0c71ac
}

a:focus {
    outline: 2px solid #0c71ac;
    outline-offset: -2px
}

a:focus:not(.focus-visible),
a:focus:not(:focus-visible) {
    outline: none
}

a:hover {
    text-decoration: none
}

b,
strong {
    font-weight: 600
}

cite,
q {
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit
}

q:after,
q:before {
    content: ""
}

.vu-container {
    box-sizing: border-box;
    max-width: 94rem;
    margin: 0 auto;
    width: 100%
}

@media screen and (min-width:0) {
    .vu-container {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:576px) {
    .vu-container {
        padding-right: 2.125rem;
        padding-left: 2.125rem
    }
}

@media screen and (min-width:768px) {
    .vu-container {
        padding-right: 2.5rem;
        padding-left: 2.5rem
    }
}

@media screen and (min-width:992px) {
    .vu-container {
        padding-right: 2rem;
        padding-left: 2rem
    }
}

@media screen and (min-width:1264px) {
    .vu-container {
        padding-right: 3rem;
        padding-left: 3rem
    }
}

@media screen and (min-width:1920px) {
    .vu-container {
        padding-right: 3rem;
        padding-left: 3rem
    }
}

@media screen and (min-width:0) {
    .vu-component-gutter {
        margin-bottom: 1rem
    }
}

@media screen and (min-width:992px) {
    .vu-component-gutter {
        margin-bottom: 1.5rem
    }
}

.vu-button--primary,
.vu-button--secondary:hover,
.vu-button--transparent-on-light:hover,
.vu-button--transparent:hover {
    border: 2px solid #1e1248;
    background: #1e1248;
    color: #fff
}

.vu-button--special,
.vu-button--special-on-dark,
.vu-button--special-on-light {
    border: 2px solid #ed6b5e;
    background: #ed6b5e;
    color: #1e1248
}

.vu-button--primary:hover,
.vu-button--secondary {
    border: 2px solid #1e1248;
    background: #fff;
    color: #1e1248
}

.vu-button {
    display: inline-block;
    padding: .625rem 1.875rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer
}

.vu-button.vu-link:focus,
.vu-button.vu-link:hover {
    text-decoration: none
}

.vu-button--special-on-light:hover,
.vu-button--special:hover {
    border: 2px solid #1e1248;
    background: #1e1248;
    color: #ed6b5e
}

.vu-button--special-on-dark:hover {
    border: 2px solid #fff;
    background: #fff;
    color: #1e1248
}

.vu-button--tertiary {
    border: 2px solid #eee;
    text-transform: inherit;
    background: #eee;
    color: #1e1248
}

.vu-button--tertiary:hover {
    text-decoration: underline
}

.vu-button--transparent,
.vu-button--transparent-on-light {
    border: 2px solid #1e1248;
    background: transparent;
    color: #1e1248
}

.vu-button--transparent-on-dark {
    border: 2px solid #fff;
    background: transparent;
    color: #fff
}

.vu-button--transparent-on-dark:hover {
    background: #fff;
    color: #1e1248
}

.vu-button--l {
    padding: .875rem 2.875rem;
    font-size: 1.5rem;
    line-height: 2rem
}

.vu-button--s {
    padding: .375rem 1.375rem;
    font-size: 1rem;
    line-height: 1.5rem
}

.vu-button--xs {
    padding: .25rem .875rem;
    font-size: .875rem;
    line-height: 1.25rem
}

.vu-button--disabled,
.vu-button :disabled {
    cursor: default
}

.vu-button--disabled,
.vu-button--disabled:hover,
.vu-button :disabled,
.vu-button :disabled:hover {
    border: 2px solid #b7b7b7;
    background: #b7b7b7;
    color: #262626
}

.vu-spinner {
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: spin;
    animation-name: spin;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear
}

.u-bg-color__primary {
    background-color: #5bc2e7!important
}

.u-txt-color__primary {
    color: #5bc2e7!important
}

.u-bg-color__primary-dark {
    background-color: #0c71ac!important
}

.u-txt-color__primary-dark {
    color: #0c71ac!important
}

.u-bg-color__primary-light {
    background-color: #eff9fd!important
}

.u-txt-color__primary-light {
    color: #eff9fd!important
}

.u-bg-color__black {
    background-color: #000!important
}

.u-txt-color__black {
    color: #000!important
}

.u-bg-color__black-85 {
    background-color: #4d4d4f!important
}

.u-txt-color__black-85 {
    color: #4d4d4f!important
}

.u-bg-color__black-55 {
    background-color: #8a8c8e!important
}

.u-txt-color__black-55 {
    color: #8a8c8e!important
}

.u-bg-color__white {
    background-color: #fff!important
}

.u-txt-color__white {
    color: #fff!important
}

.u-bg-color__aubergine {
    background-color: #1e1248!important
}

.u-txt-color__aubergine {
    color: #1e1248!important
}

.u-bg-color__aubergine-75 {
    background-color: #544b73!important
}

.u-txt-color__aubergine-75 {
    color: #544b73!important
}

.u-bg-color__grey-secondary {
    background-color: #ececec!important
}

.u-txt-color__grey-secondary {
    color: #ececec!important
}

.u-bg-color__yellow {
    background-color: #fed76f!important
}

.u-txt-color__yellow {
    color: #fed76f!important
}

.u-bg-color__melon {
    background-color: #ed6b5e!important
}

.u-txt-color__melon {
    color: #ed6b5e!important
}

.u-bg-color__melon-85 {
    background-color: #f6958a!important
}

.u-txt-color__melon-85 {
    color: #f6958a!important
}

.u-bg-color__grape {
    background-color: #b49ad2!important
}

.u-txt-color__grape {
    color: #b49ad2!important
}

.u-bg-color__tango {
    background-color: #f79554!important
}

.u-txt-color__tango {
    color: #f79554!important
}

.u-bg-color__grey-dark {
    background-color: #494949!important
}

.u-txt-color__grey-dark {
    color: #494949!important
}

.u-bg-color__grey-medium {
    background-color: #b7b7b7!important
}

.u-txt-color__grey-medium {
    color: #b7b7b7!important
}

.u-bg-color__grey-light {
    background-color: #eee!important
}

.u-txt-color__grey-light {
    color: #eee!important
}

.u-bg-color__grey-light-on-light {
    background-color: #6e6e6e!important
}

.u-txt-color__grey-light-on-light {
    color: #6e6e6e!important
}

.u-bg-color__grey-extra-light {
    background-color: #f7f7f7!important
}

.u-txt-color__grey-extra-light {
    color: #f7f7f7!important
}

.u-bg-color__negative {
    background-color: #d6001d!important
}

.u-txt-color__negative {
    color: #d6001d!important
}

.u-bg-color__positive {
    background-color: #498406!important
}

.u-txt-color__positive {
    color: #498406!important
}

.u-bg-color__text {
    background-color: #262626!important
}

.u-txt-color__text {
    color: #262626!important
}

.u-bg-color__vic-poly {
    background-color: #1da593!important
}

.u-txt-color__vic-poly {
    color: #1da593!important
}

.vu-visually-hidden {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    pointer-events: none
}

.vu-hidden {
    display: none;
    visibility: hidden
}

.vu-markup .media--type-embedded-video .video-embed-field-responsive-video {
    position: relative;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 56.25%;
    height: 0
}

.vu-markup .media--type-embedded-video .video-embed-field-responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.vu-markup .media--type-embedded-video .field--name-field-media-link {
    padding: 1rem 0 1rem 1.25rem;
    //background: 0 50% url(/img/view.svg) no-repeat transparent
}

.vu-markup .media--type-embedded-video .field--name-field-media-link a {
    color: #5bc2e7
}

.vu-markup .embedded-entity {
    display: block;
    margin: 0 0 2.5rem
}

.vu-markup .embedded-entity figcaption {
    margin-top: .75rem;
    text-transform: uppercase;
    color: #494949
}

.tide-preview-mode .vu-child-component-error {
    display: block
}

.tide-preview-mode .vu-child-component-error:before {
    padding: 1rem;
    color: #d6001d;
    content: "[Ripple Warn] Something wrong to render this component. This message won't show on production."
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@media screen and (max-width:991px) {
    .vu-site-layout {
        padding-top: 3rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: #000;
    content: "";
    opacity: 0;
    pointer-events: none;
    transition: opacity .25s ease
}

.vu-navigation__overlay--visible {
    opacity: .3;
    pointer-events: auto
}

.vu-navigation__extra-controls {
    display: flex
}

.vu-navigation__extra-controls .vu-navigation__login-button {
    flex: 1 0 auto
}

.vu-navigation__close-button,
.vu-navigation__login-button {
    border: 0;
    cursor: pointer
}

.vu-navigation__close-button {
    flex: 0 0 auto;
    padding: 1rem;
    background: #000
}

.vu-navigation__level-1__list-wrapper {
    position: relative
}

.vu-navigation__level-1__links {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none
}

.vu-navigation__level-1__links li {
    margin: 0
}

.vu-navigation__level-1 .vu-text-link {
    text-decoration: none
}

@media screen and (max-width:991px) {
    .vu-navigation {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        height: 3rem
    }
    .vu-navigation__level-1 {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        z-index: 10;
        overflow-y: auto;
        width: 300px;
        background: #fff;
        transform: translateX(-300px)
    }
    .vu-navigation__level-1 .vu-container {
        padding: 0
    }
    .vu-navigation__level-1__links {
        flex-flow: column
    }
    .vu-navigation__level-1__links-enter-active,
    .vu-navigation__level-1__links-leave-active {
        transition: transform .25s ease
    }
    .vu-navigation__level-1__links-enter,
    .vu-navigation__level-1__links-leave-to {
        transform: translateX(-100%)
    }
    .vu-navigation__level-1__link {
        display: block;
        border-bottom: 1px solid #b7b7b7;
        padding: 1.5rem 1rem;
        text-decoration: none;
        background: #eee;
        color: #262626
    }
    .vu-navigation__level-1__link--active,
    .vu-navigation__level-1__link:focus,
    .vu-navigation__level-1__link:hover {
        background: #5bc2e7
    }
    .vu-navigation__level-1__link:focus {
        border-bottom-color: #0c71ac
    }
    .vu-navigation__level-1-enter-active,
    .vu-navigation__level-1-leave-active {
        transition: transform .25s ease
    }
    .vu-navigation__level-1-enter,
    .vu-navigation__level-1-leave-to {
        transform: translateX(0)
    }
    .vu-navigation__level-1__menu {
        display: flex;
        flex-flow: column
    }
    .vu-navigation__level-1--unhydrated,
    .vu-navigation__level-1__menu>.vu-navigation__login-button,
    .vu-navigation__level-1__menu>.vu-navigation__search-button {
        display: none
    }
    .vu-navigation__login-button {
        padding: 1rem;
        text-align: left;
        background: #000;
        color: #fff
    }
    .vu-navigation__login-button>.vu-icon {
        margin: 0 0 0 .5rem;
        transform: scale(.5);
        transition: transform .25s ease
    }
    .vu-navigation__login-button--open>.vu-icon {
        transform: scale(.5) rotate(-180deg)
    }
}

@media screen and (min-width:992px) {
    .vu-navigation__level-1 {
        position: relative;
        z-index: 11;
        background: #eee
    }
    .vu-navigation__level-1__menu {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none
    }
    .vu-navigation__level-1__links {
        flex-flow: row wrap;
        font-size: 1rem;
        line-height: 1.5rem
    }
    .vu-navigation__level-1__link-wrapper {
        display: flex
    }
    .vu-navigation__level-1__link-wrapper:last-of-type {
        position: relative
    }
    .vu-navigation__level-1__link-wrapper:last-of-type:before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 1rem;
        background: #000;
        content: "";
        transform: translateY(-50%)
    }
    .vu-navigation__level-1__list-wrapper {
        display: flex
    }
    .vu-navigation__level-1__link {
        align-items: center;
        display: flex;
        padding: 0 .75rem;
        font-weight: 600;
        color: #262626
    }
    .vu-navigation__level-1__link--active,
    .vu-navigation__level-1__link:focus,
    .vu-navigation__level-1__link:hover {
        background: #fff;
        color: #0c71ac
    }
    .vu-navigation__level-1 .vu-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between
    }
    .vu-navigation__login-button {
        padding: 1rem;
        font-weight: 600;
        background: none
    }
    .vu-navigation__login-button:disabled {
        cursor: auto;
        opacity: .5
    }
}

@media screen and (min-width:1264px) {
    .vu-navigation__level-1__link {
        padding: 0 1.25rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-skip-link {
    background: #eee
}

.vu-skip-link .vu-container,
.vu-skip-link .vu-skip-link__link {
    display: flex
}

.vu-skip-link .vu-skip-link__link {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    pointer-events: none;
    align-items: center;
    padding: 1rem;
    text-decoration: none;
    color: #0c71ac;
    cursor: pointer
}

.vu-skip-link .vu-skip-link__link:focus,
.vu-skip-link .vu-skip-link__link:hover {
    text-decoration: underline
}

.vu-skip-link--show .vu-skip-link__link,
.vu-skip-link.focus-within .vu-skip-link__link,
.vu-skip-link[focus-within] .vu-skip-link__link {
    position: static;
    margin: 0;
    width: auto;
    height: auto;
    pointer-events: auto
}

.vu-skip-link--show .vu-skip-link__link,
.vu-skip-link.focus-within .vu-skip-link__link,
.vu-skip-link:focus-within .vu-skip-link__link {
    position: static;
    margin: 0;
    width: auto;
    height: auto;
    pointer-events: auto
}

.vu-skip-link--show .vu-skip-link__divider,
.vu-skip-link.focus-within .vu-skip-link__divider,
.vu-skip-link[focus-within] .vu-skip-link__divider {
    padding: 1rem 0
}

.vu-skip-link--show .vu-skip-link__divider,
.vu-skip-link.focus-within .vu-skip-link__divider,
.vu-skip-link:focus-within .vu-skip-link__divider {
    padding: 1rem 0
}

.vu-skip-link--show .vu-skip-link__divider:before,
.vu-skip-link.focus-within .vu-skip-link__divider:before,
.vu-skip-link[focus-within] .vu-skip-link__divider:before {
    content: "|"
}

.vu-skip-link--show .vu-skip-link__divider:before,
.vu-skip-link.focus-within .vu-skip-link__divider:before,
.vu-skip-link:focus-within .vu-skip-link__divider:before {
    content: "|"
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation-bar__title {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem
}

@media screen and (min-width:992px) {
    .vu-navigation-bar__title {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

.vu-navigation-bar {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .07);
    transition: transform .25s ease
}

.vu-navigation-bar .vu-container {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between
}

.vu-navigation-bar__beginning {
    display: flex
}

.vu-navigation-bar__logo,
.vu-navigation-bar__logo img {
    display: block
}

.vu-navigation-bar__logo img {
    height: 1.5rem
}

.vu-navigation-bar__title {
    margin: 0;
    font-weight: 600
}

.vu-navigation-bar .vu-navigation__search-button,
.vu-navigation-bar__toggle-button {
    padding: 0 1rem;
    height: 3rem
}

.vu-navigation-bar__toggle-button {
    border: 0;
    background: none;
    cursor: pointer
}

.vu-navigation-bar--open {
    transform: translateX(-300px)
}

@media screen and (max-width:991px) {
    .vu-navigation-bar__title {
        display: none
    }
}

@media screen and (min-width:992px) {
    .vu-navigation-bar {
        display: none
    }
    .vu-navigation-bar__logo {
        margin: 0 1.25rem 0 0
    }
    .vu-navigation-bar__toggle-button {
        display: none
    }
    .vu-navigation-bar--sticky {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 12;
        display: block
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-link {
    text-decoration: none
}

@media print {
    .vu-link[href]:after {
        content: " <" attr(data-print-url) "> "
    }
    .vu-link[href^="tel:"]:after {
        content: ""
    }
}

.vu-link__inner {
    display: inline
}

@media print {
    .vu-link__inner {
        text-decoration: underline
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-icon {
    height: 1em;
    color: inherit
}

.vu-icon__color-primary {
    color: #5bc2e7
}

.vu-icon__color-primary-dark {
    color: #0c71ac
}

.vu-icon__color-primary-light {
    color: #eff9fd
}

.vu-icon__color-black {
    color: #000
}

.vu-icon__color-black-85 {
    color: #4d4d4f
}

.vu-icon__color-black-55 {
    color: #8a8c8e
}

.vu-icon__color-white {
    color: #fff
}

.vu-icon__color-aubergine {
    color: #1e1248
}

.vu-icon__color-aubergine-75 {
    color: #544b73
}

.vu-icon__color-grey-secondary {
    color: #ececec
}

.vu-icon__color-yellow {
    color: #fed76f
}

.vu-icon__color-melon {
    color: #ed6b5e
}

.vu-icon__color-melon-85 {
    color: #f6958a
}

.vu-icon__color-grape {
    color: #b49ad2
}

.vu-icon__color-tango {
    color: #f79554
}

.vu-icon__color-grey-dark {
    color: #494949
}

.vu-icon__color-grey-medium {
    color: #b7b7b7
}

.vu-icon__color-grey-light {
    color: #eee
}

.vu-icon__color-grey-light-on-light {
    color: #6e6e6e
}

.vu-icon__color-grey-extra-light {
    color: #f7f7f7
}

.vu-icon__color-negative {
    color: #d6001d
}

.vu-icon__color-positive {
    color: #498406
}

.vu-icon__color-text {
    color: #262626
}

.vu-icon__color-vic-poly {
    color: #1da593
}

.vu-icon__xmega {
    font-size: 2.125rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__xmega {
        width: 2.65625rem;
        height: 2.65625rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__xmega {
        font-size: 5rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__xmega {
        width: 2.65625rem;
        height: 2.65625rem
    }
}

.vu-icon__mega {
    font-size: 2.125rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__mega {
        width: 2.65625rem;
        height: 2.65625rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__mega {
        font-size: 3.5rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__mega {
        width: 2.65625rem;
        height: 2.65625rem
    }
}

.vu-icon__xxxl {
    font-size: 1.75rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__xxxl {
        width: 2.1875rem;
        height: 2.1875rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__xxxl {
        font-size: 2.75rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__xxxl {
        width: 2.1875rem;
        height: 2.1875rem
    }
}

.vu-icon__xxl {
    font-size: 1.5rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__xxl {
        width: 1.875rem;
        height: 1.875rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__xxl {
        font-size: 2rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__xxl {
        width: 1.875rem;
        height: 1.875rem
    }
}

.vu-icon__xl {
    font-size: 1.25rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__xl {
        width: 1.5625rem;
        height: 1.5625rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__xl {
        font-size: 1.5rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__xl {
        width: 1.5625rem;
        height: 1.5625rem
    }
}

.vu-icon__l {
    font-size: 1.125rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__l {
        width: 1.40625rem;
        height: 1.40625rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__l {
        font-size: 1.25rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__l {
        width: 1.40625rem;
        height: 1.40625rem
    }
}

.vu-icon__m {
    font-size: 1rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__m {
        width: 1.25rem;
        height: 1.25rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__m {
        font-size: 1.125rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__m {
        width: 1.25rem;
        height: 1.25rem
    }
}

.vu-icon__s {
    font-size: .875rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__s {
        width: 1.09375rem;
        height: 1.09375rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__s {
        font-size: 1rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__s {
        width: 1.09375rem;
        height: 1.09375rem
    }
}

.vu-icon__xs {
    font-size: .75rem;
    line-height: .75
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-icon__xs {
        width: .9375rem;
        height: .9375rem
    }
}

@media screen and (min-width:768px) {
    .vu-icon__xs {
        font-size: .875rem
    }
}

@media screen and (min-width:768px) and (-ms-high-contrast:active),
screen and (min-width:768px) and (-ms-high-contrast:none) {
    .vu-icon__xs {
        width: .9375rem;
        height: .9375rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation__search-button {
    border: 0;
    padding: 1rem;
    background: #5bc2e7;
    cursor: pointer
}

.vu-navigation__search-button:disabled {
    cursor: auto;
    opacity: .5
}

.vu-text-icon--nowrap {
    white-space: nowrap
}

.vu-text-icon--before {
    margin-right: .5rem
}

.vu-text-icon--after {
    margin-left: .5rem
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-login-concierge {
    padding-bottom: 5rem
}

.vu-login-concierge__cell {
    display: flex
}

.vu-login-concierge__icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 .5rem;
    width: 1.5rem;
    height: 1.5rem;
    background: #5bc2e7;
    color: #000;
    transform: rotate(45deg)
}

.vu-login-concierge__icon .vu-icon {
    transform: rotate(-45deg)
}

.vu-login-concierge__description {
    margin: 1.5rem 0 2rem
}

.vu-login-concierge__feature-link {
    display: flex;
    padding: 1.5rem 1rem;
    background: hsla(0, 0%, 100%, .08);
    color: #fff
}

.vu-login-concierge__feature-link .h6 {
    margin: .5rem 0
}

.vu-login-concierge__feature-link:hover {
    background: #5bc2e7;
    color: #000
}

.vu-login-concierge__feature-link:hover .vu-login-concierge__icon {
    background: #000;
    color: #5bc2e7
}

.vu-login-concierge__feature-link:hover .vu-login-concierge__link-cta {
    text-decoration: underline
}

.vu-login-concierge__feature-link .vu-link__inner {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 100%
}

@media screen and (min-width:1264px) {
    .vu-login-concierge__feature-link {
        padding: 1.5rem
    }
}

@media screen and (min-width:1920px) {
    .vu-login-concierge__feature-link {
        padding: 1.5rem 2rem
    }
}

@media screen and (max-width:991px) {
    .vu-login-concierge {
        padding: .5rem .75rem;
        background: #000
    }
    .vu-login-concierge__feature-link {
        margin: 0 0 .25rem;
        padding: 1rem
    }
    .vu-login-concierge__feature-link .vu-link__inner {
        align-items: center;
        display: flex;
        flex-flow: row;
        justify-content: space-between
    }
    .vu-login-concierge__link-title {
        margin: .5rem 0;
        font-size: 1rem
    }
    .vu-login-concierge-enter-active,
    .vu-login-concierge-leave-active {
        overflow: hidden;
        transition: height .5s
    }
    .vu-login-concierge-enter,
    .vu-login-concierge-leave-to {
        height: 0!important
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-text-link {
    text-decoration: underline
}

.vu-text-link__external-icon {
    position: relative;
    vertical-align: middle;
    margin: 0 0 0 .25rem;
    font-size: .75rem;
    transform: translateY(-2px)
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-text-link__external-icon {
        width: .75rem;
        height: .75rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation-accordions {
    margin: 0
}

.vu-navigation-accordion__title {
    align-items: stretch;
    display: flex;
    border-bottom: 1px solid #b7b7b7
}

.vu-navigation-accordion__title--active .vu-navigation-accordion__title-link {
    background: #5bc2e7
}

.vu-navigation-accordion__title-link {
    flex: 1 1 auto;
    padding: 1.5rem 1rem 1.5rem 1.5rem;
    font-weight: 600;
    text-decoration: none;
    color: #262626
}

.vu-navigation-accordion__title-link:focus,
.vu-navigation-accordion__title-link:hover {
    background: #5bc2e7
}

.vu-navigation-accordion__button {
    border: 0;
    border-left: 1px solid #b7b7b7;
    padding: 0 1.5rem;
    background: #1e1248;
    color: #fff;
    cursor: pointer
}

.vu-navigation-accordion__button .vu-icon {
    transition: transform .25s
}

.vu-navigation-accordion__title--open .vu-navigation-accordion__button {
    background: #5bc2e7;
    color: #262626
}

.vu-navigation-accordion__title--open .vu-navigation-accordion__button .vu-icon {
    transform: rotate(-180deg)
}

.vu-navigation-accordion__content {
    margin: 0
}

.vu-navigation-accordion__content-enter-active,
.vu-navigation-accordion__content-leave-active {
    overflow: hidden;
    transition: height .5s
}

.vu-navigation-accordion__content-enter,
.vu-navigation-accordion__content-leave-to {
    height: 0!important
}

.vu-navigation-accordion--unhydrated {
    display: none
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation-sublevel-link-list {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-navigation-sublevel-link-list {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.vu-navigation-sublevel-link-list {
    margin: 0;
    padding: 0;
    list-style: none
}

.vu-navigation-sublevel-link-list__link {
    align-items: stretch;
    display: flex;
    justify-content: space-between;
    margin: 0;
    border-bottom: 1px solid #b7b7b7;
    text-decoration: none;
    color: #262626
}

.vu-navigation-sublevel-link-list__link .vu-link {
    display: block;
    flex: 1 1 auto;
    padding: 1.5rem 1rem 1.5rem 1.75rem;
    text-decoration: inherit;
    background: rgba(91, 194, 231, .1);
    color: inherit
}

.vu-navigation-sublevel-link-list__children-toggle {
    border: 0;
    border-left: 1px solid #b7b7b7;
    padding: 0 1.5rem;
    background: #fff;
    color: inherit;
    cursor: pointer
}

@media screen and (max-width:991px) {
    .vu-navigation-sublevel-link-list__link .vu-link:focus,
    .vu-navigation-sublevel-link-list__link .vu-link:hover {
        background: #5bc2e7
    }
    .vu-navigation-sublevel-link-list__link--active {
        background: #5bc2e7;
        color: #1e1248
    }
}

@media screen and (min-width:992px) {
    .vu-navigation-sublevel-link-list {
        border-top: 1px solid #b7b7b7
    }
    .vu-navigation-sublevel-link-list__link .vu-link {
        padding: 1rem .5rem 1rem 1rem;
        background: transparent
    }
    .vu-navigation-sublevel-link-list__link .vu-link:focus,
    .vu-navigation-sublevel-link-list__link .vu-link:hover {
        text-decoration: underline;
        color: #0c71ac
    }
    .vu-navigation-sublevel-link-list__link--active .vu-link,
    .vu-navigation-sublevel-link-list__link--open .vu-navigation-sublevel-link-list__children-toggle {
        background: #5bc2e7;
        color: #1e1248
    }
    .vu-navigation-sublevel-link-list__children-toggle {
        border-left: 0;
        padding: .5rem 1rem
    }
    .vu-navigation-sublevel-link-list__children-toggle .vu-icon {
        transform: scale(.85)
    }
    .vu-navigation-sublevel-link-list__children-toggle:hover {
        background: #000;
        color: #fff
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation-drawer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    min-height: 100%;
    background: #fff;
    transform: translateX(0)
}

.vu-navigation-drawer .vu-navigation-accordion__title-link {
    font-weight: 400
}

.vu-navigation-drawer .vu-navigation-sublevel-link-list {
    border-bottom: 1px solid #b7b7b7
}

.vu-navigation-drawer .vu-navigation-sublevel-link-list__link {
    border-bottom: 0
}

.vu-navigation-drawer .vu-navigation-sublevel-link-list__link .vu-link {
    padding: 1rem 1rem 1rem 1.75rem
}

.vu-navigation-drawer .vu-navigation-sublevel-link-list__link .vu-link:before {
    margin-right: .5rem;
    content: "\2022"
}

.vu-navigation-drawer__parent {
    display: flex;
    border-bottom: 1px solid #b7b7b7
}

.vu-navigation-drawer__parent-button {
    border: 0;
    border-right: 1px solid #b7b7b7;
    padding: 0 1.5rem;
    background: #fff;
    color: #262626;
    cursor: pointer
}

.vu-navigation-drawer__parent-link {
    flex: 1 1 auto;
    padding: 1.5rem 1rem;
    text-decoration: none;
    background: rgba(91, 194, 231, .1);
    color: #262626
}

.vu-navigation-drawer__parent-link:focus,
.vu-navigation-drawer__parent-link:hover {
    background: #5bc2e7
}

.vu-navigation-drawer-enter-active,
.vu-navigation-drawer-leave-active {
    transition: transform .25s ease
}

.vu-navigation-drawer-enter,
.vu-navigation-drawer-leave-to {
    transform: translateX(100%)
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader {
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin: .5rem 0;
    font-size: 1.25rem
}

@media screen and (min-width:992px) {
    .vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader {
        margin: 1rem 0;
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.vu-search-concierge__form .vu-search-concierge__search-input .form-control {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-search-concierge__form .vu-search-concierge__search-input .form-control {
        font-size: 1.25rem
    }
}

.vu-search-concierge__list {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-search-concierge__list {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.vu-search-concierge__list__subtitle {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem
}

@media screen and (min-width:992px) {
    .vu-search-concierge__list__subtitle {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

.vu-search-concierge {
    background: #000
}

.vu-search-concierge__form fieldset {
    display: flex;
    margin: 0;
    width: 100%
}

.vu-search-concierge__form .vu-search-concierge__search-input,
.vu-search-concierge__form .vu-search-concierge__search-submit {
    margin: 0 0 .25rem
}

.vu-search-concierge__form .vu-search-concierge__search-input {
    flex: 1 1 auto
}

.vu-search-concierge__form .vu-search-concierge__search-input .field-wrap,
.vu-search-concierge__form .vu-search-concierge__search-input .form-control,
.vu-search-concierge__form .vu-search-concierge__search-input .wrapper {
    height: 100%
}

.vu-search-concierge__form .vu-search-concierge__search-input .form-control {
    border-color: #fff;
    border-radius: 0
}

.vu-search-concierge__form .vu-search-concierge__search-input label {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    pointer-events: none
}

.vu-search-concierge__form .vu-search-concierge__search-submit .field-wrap,
.vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader {
    height: 100%
}

.vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader {
    margin: 0;
    border: 0;
    border-radius: 0;
    padding: 1rem 3rem;
    line-height: 1.75rem;
    background-color: #5bc2e7;
    color: #000
}

@media screen and (max-width:991px) {
    .vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader {
        padding: 1rem
    }
    .vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader__text {
        position: absolute;
        overflow: hidden;
        margin: -1px;
        border: 0;
        padding: 0;
        width: 1px;
        height: 1px;
        clip: rect(0 0 0 0);
        pointer-events: none
    }
    .vu-search-concierge__form .vu-search-concierge__search-submit .vu-submit-loader:after {
        display: block;
        width: 1.75rem;
        height: 1.75rem;
        background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E") 50%/80% no-repeat;
        content: ""
    }
}

.vu-search-concierge__suggestions {
    padding: 0 0 .75rem;
    background: #fff
}

.vu-search-concierge__suggestions .h4 {
    margin: 1rem 0
}

@media screen and (min-width:768px) {
    .vu-search-concierge__suggestions>.vu-grid-container__inner {
        padding: 0 1.25rem
    }
}

.vu-search-concierge__list {
    padding: 0;
    list-style: none
}

.vu-search-concierge__list li {
    margin: 1rem 0
}

.vu-search-concierge__list .vu-link {
    text-decoration: none;
    color: #262626
}

.vu-search-concierge__list .vu-link:focus,
.vu-search-concierge__list .vu-link:hover {
    text-decoration: underline;
    color: #0c71ac
}

.vu-search-concierge__list__subtitle {
    color: #6e6e6e
}

@media screen and (min-width:768px) {
    .vu-search-concierge {
        min-height: 100vh
    }
}

@media screen and (min-width:768px) and (min-height:51.25rem) {
    .vu-search-concierge {
        min-height: 51.25rem;
        background: #000 left bottom 2.5rem/3rem 6.5rem round no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 79 179'%3E%3Cpath fill='%235BC2E7' d='M30.07 9.43L39.5 0l9.43 9.43-9.43 9.43-9.43-9.43zM30.07 169.57l9.43-9.43 9.43 9.43L39.5 179l-9.43-9.43zM30.07 89.5l9.43-9.43 9.43 9.43-9.43 9.43-9.43-9.43z'/%3E%3C/svg%3E")
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-concierge-popover {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 12;
    overflow: auto;
    padding: 2rem 0;
    height: 100vh;
    background: #000;
    transform: translateY(0)
}

@media screen and (min-width:992px) {
    .vu-concierge-popover {
        background-size: auto 11rem
    }
}

.vu-concierge-popover__header {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 2rem
}

@media screen and (min-width:768px) {
    .vu-concierge-popover__header {
        justify-content: space-between;
        margin: 0 0 3.75rem
    }
}

.vu-concierge-popover__logo {
    height: 3.5rem
}

@media screen and (max-width:767px) {
    .vu-concierge-popover__logo {
        display: none
    }
}

.vu-concierge-popover__toggle-button {
    border: 0;
    background: none;
    cursor: pointer
}

.vu-concierge-popover-enter-active,
.vu-concierge-popover-leave-active {
    transition: transform .25s ease
}

.vu-concierge-popover-enter,
.vu-concierge-popover-leave-to {
    transform: translateY(-100%)
}

@media screen and (min-width:768px) {
    .vu-concierge-popover {
        padding-top: 4rem;
        height: auto;
        max-height: 100vh
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-button,
.vu-form .buttons button,
.vu-form [type=submit] {
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
    text-transform: uppercase
}

.vu-button--primary,
.vu-button--secondary:hover,
.vu-button--transparent-on-light:hover,
.vu-button--transparent:hover,
.vu-form [type=submit] {
    border: 2px solid #1e1248;
    background: #1e1248;
    color: #fff
}

.vu-button--special,
.vu-button--special-on-dark,
.vu-button--special-on-light {
    border: 2px solid #ed6b5e;
    background: #ed6b5e;
    color: #1e1248
}

.vu-button--primary:hover,
.vu-button--secondary,
.vu-form [type=submit]:hover {
    border: 2px solid #1e1248;
    background: #fff;
    color: #1e1248
}

.vu-button {
    display: inline-block;
    padding: .625rem 1.875rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer
}

.vu-button.vu-link:focus,
.vu-button.vu-link:hover {
    text-decoration: none
}

.vu-button--special-on-light:hover,
.vu-button--special:hover {
    border: 2px solid #1e1248;
    background: #1e1248;
    color: #ed6b5e
}

.vu-button--special-on-dark:hover {
    border: 2px solid #fff;
    background: #fff;
    color: #1e1248
}

.vu-button--tertiary {
    border: 2px solid #eee;
    text-transform: inherit;
    background: #eee;
    color: #1e1248
}

.vu-button--tertiary:hover {
    text-decoration: underline
}

.vu-button--transparent,
.vu-button--transparent-on-light {
    border: 2px solid #1e1248;
    background: transparent;
    color: #1e1248
}

.vu-button--transparent-on-dark {
    border: 2px solid #fff;
    background: transparent;
    color: #fff
}

.vu-button--transparent-on-dark:hover {
    background: #fff;
    color: #1e1248
}

.vu-button--l {
    padding: .875rem 2.875rem;
    font-size: 1.5rem;
    line-height: 2rem
}

.vu-button--s {
    padding: .375rem 1.375rem;
    font-size: 1rem;
    line-height: 1.5rem
}

.vu-button--xs {
    padding: .25rem .875rem;
    font-size: .875rem;
    line-height: 1.25rem
}

.vu-button--disabled,
.vu-button :disabled {
    cursor: default
}

.vu-button--disabled,
.vu-button--disabled:hover,
.vu-button :disabled,
.vu-button :disabled:hover {
    border: 2px solid #b7b7b7;
    background: #b7b7b7;
    color: #262626
}

.vu-form__title {
    margin-top: 0
}

.vu-form label:not(.vu-option-button__label) {
    display: block;
    margin-bottom: .75rem;
    color: #262626
}

.vu-form input:not([type]),
.vu-form input[type=color],
.vu-form input[type=date],
.vu-form input[type=datetime-local],
.vu-form input[type=datetime],
.vu-form input[type=email],
.vu-form input[type=month],
.vu-form input[type=number],
.vu-form input[type=password],
.vu-form input[type=search],
.vu-form input[type=tel],
.vu-form input[type=text],
.vu-form input[type=time],
.vu-form input[type=url],
.vu-form input[type=week] {
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #b7b7b7;
    border-radius: .25rem;
    padding: 1rem;
    width: 100%;
    height: auto;
    background-color: #fff;
    color: #262626
}

.vu-form input:not([type]):focus,
.vu-form input[type=color]:focus,
.vu-form input[type=date]:focus,
.vu-form input[type=datetime-local]:focus,
.vu-form input[type=datetime]:focus,
.vu-form input[type=email]:focus,
.vu-form input[type=month]:focus,
.vu-form input[type=number]:focus,
.vu-form input[type=password]:focus,
.vu-form input[type=search]:focus,
.vu-form input[type=tel]:focus,
.vu-form input[type=text]:focus,
.vu-form input[type=time]:focus,
.vu-form input[type=url]:focus,
.vu-form input[type=week]:focus {
    outline: 2px solid #0c71ac;
    outline-offset: -2px
}

.vu-form input:not([type]):focus:not(.focus-visible),
.vu-form input:not([type]):focus:not(:focus-visible),
.vu-form input[type=color]:focus:not(.focus-visible),
.vu-form input[type=color]:focus:not(:focus-visible),
.vu-form input[type=date]:focus:not(.focus-visible),
.vu-form input[type=date]:focus:not(:focus-visible),
.vu-form input[type=datetime-local]:focus:not(.focus-visible),
.vu-form input[type=datetime-local]:focus:not(:focus-visible),
.vu-form input[type=datetime]:focus:not(.focus-visible),
.vu-form input[type=datetime]:focus:not(:focus-visible),
.vu-form input[type=email]:focus:not(.focus-visible),
.vu-form input[type=email]:focus:not(:focus-visible),
.vu-form input[type=month]:focus:not(.focus-visible),
.vu-form input[type=month]:focus:not(:focus-visible),
.vu-form input[type=number]:focus:not(.focus-visible),
.vu-form input[type=number]:focus:not(:focus-visible),
.vu-form input[type=password]:focus:not(.focus-visible),
.vu-form input[type=password]:focus:not(:focus-visible),
.vu-form input[type=search]:focus:not(.focus-visible),
.vu-form input[type=search]:focus:not(:focus-visible),
.vu-form input[type=tel]:focus:not(.focus-visible),
.vu-form input[type=tel]:focus:not(:focus-visible),
.vu-form input[type=text]:focus:not(.focus-visible),
.vu-form input[type=text]:focus:not(:focus-visible),
.vu-form input[type=time]:focus:not(.focus-visible),
.vu-form input[type=time]:focus:not(:focus-visible),
.vu-form input[type=url]:focus:not(.focus-visible),
.vu-form input[type=url]:focus:not(:focus-visible),
.vu-form input[type=week]:focus:not(.focus-visible),
.vu-form input[type=week]:focus:not(:focus-visible) {
    outline: none
}

.vu-form input:not([type])::-moz-placeholder,
.vu-form input[type=color]::-moz-placeholder,
.vu-form input[type=date]::-moz-placeholder,
.vu-form input[type=datetime-local]::-moz-placeholder,
.vu-form input[type=datetime]::-moz-placeholder,
.vu-form input[type=email]::-moz-placeholder,
.vu-form input[type=month]::-moz-placeholder,
.vu-form input[type=number]::-moz-placeholder,
.vu-form input[type=password]::-moz-placeholder,
.vu-form input[type=search]::-moz-placeholder,
.vu-form input[type=tel]::-moz-placeholder,
.vu-form input[type=text]::-moz-placeholder,
.vu-form input[type=time]::-moz-placeholder,
.vu-form input[type=url]::-moz-placeholder,
.vu-form input[type=week]::-moz-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.vu-form input:not([type]):-ms-input-placeholder,
.vu-form input[type=color]:-ms-input-placeholder,
.vu-form input[type=date]:-ms-input-placeholder,
.vu-form input[type=datetime-local]:-ms-input-placeholder,
.vu-form input[type=datetime]:-ms-input-placeholder,
.vu-form input[type=email]:-ms-input-placeholder,
.vu-form input[type=month]:-ms-input-placeholder,
.vu-form input[type=number]:-ms-input-placeholder,
.vu-form input[type=password]:-ms-input-placeholder,
.vu-form input[type=search]:-ms-input-placeholder,
.vu-form input[type=tel]:-ms-input-placeholder,
.vu-form input[type=text]:-ms-input-placeholder,
.vu-form input[type=time]:-ms-input-placeholder,
.vu-form input[type=url]:-ms-input-placeholder,
.vu-form input[type=week]:-ms-input-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.vu-form input:not([type])::placeholder,
.vu-form input[type=color]::placeholder,
.vu-form input[type=date]::placeholder,
.vu-form input[type=datetime-local]::placeholder,
.vu-form input[type=datetime]::placeholder,
.vu-form input[type=email]::placeholder,
.vu-form input[type=month]::placeholder,
.vu-form input[type=number]::placeholder,
.vu-form input[type=password]::placeholder,
.vu-form input[type=search]::placeholder,
.vu-form input[type=tel]::placeholder,
.vu-form input[type=text]::placeholder,
.vu-form input[type=time]::placeholder,
.vu-form input[type=url]::placeholder,
.vu-form input[type=week]::placeholder {
    color: #6e6e6e;
    opacity: 1
}

@media screen and (min-width:768px) {
    .vu-form input:not([type]),
    .vu-form input[type=color],
    .vu-form input[type=date],
    .vu-form input[type=datetime-local],
    .vu-form input[type=datetime],
    .vu-form input[type=email],
    .vu-form input[type=month],
    .vu-form input[type=number],
    .vu-form input[type=password],
    .vu-form input[type=search],
    .vu-form input[type=tel],
    .vu-form input[type=text],
    .vu-form input[type=time],
    .vu-form input[type=url],
    .vu-form input[type=week] {
        padding: 1rem 1.25rem
    }
}

.vu-form input:not([type]):focus,
.vu-form input[type=color]:focus,
.vu-form input[type=date]:focus,
.vu-form input[type=datetime-local]:focus,
.vu-form input[type=datetime]:focus,
.vu-form input[type=email]:focus,
.vu-form input[type=month]:focus,
.vu-form input[type=number]:focus,
.vu-form input[type=password]:focus,
.vu-form input[type=search]:focus,
.vu-form input[type=tel]:focus,
.vu-form input[type=text]:focus,
.vu-form input[type=time]:focus,
.vu-form input[type=url]:focus,
.vu-form input[type=week]:focus {
    border-color: #0c71ac
}

.vu-form input[type=checkbox]+label,
.vu-form input[type=radio]:not(.vu-option-button__radio)+label {
    display: inline-block;
    margin: 0
}

.vu-form__prepend-icon .wrapper {
    position: relative
}

.vu-form__prepend-icon .wrapper:before {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 1rem;
    height: 1rem;
    background-repeat: no-repeat;
    content: ""
}

.vu-form__prepend-icon .wrapper input {
    padding-left: 2.5rem
}

.vu-form__prepend-icon--search .wrapper:before {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.99 9.243c1.597-2.45 1.224-5.82-1.133-7.833C7.59-.526 4.137-.46 1.94 1.557a6 6 0 00-.19 8.653c2.043 2.045 5.205 2.293 7.527.757.031-.02.102-.083.198-.173l4.343 4.195a.941.941 0 101.308-1.354l-4.324-4.176c.098-.106.166-.184.188-.216zm-8.023-.25a4.274 4.274 0 010-6.035 4.266 4.266 0 016.03 0 4.274 4.274 0 010 6.034 4.266 4.266 0 01-6.03 0z' fill='%23494949'/%3E%3C/svg%3E")
}

.vu-form textarea {
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #b7b7b7;
    border-radius: .25rem;
    padding: 1rem;
    width: 100%;
    height: auto;
    background-color: #fff;
    color: #262626;
    height: 11.25rem
}

.vu-form textarea:focus {
    outline: 2px solid #0c71ac;
    outline-offset: -2px
}

.vu-form textarea:focus:not(.focus-visible),
.vu-form textarea:focus:not(:focus-visible) {
    outline: none
}

.vu-form textarea::-moz-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.vu-form textarea:-ms-input-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.vu-form textarea::placeholder {
    color: #6e6e6e;
    opacity: 1
}

@media screen and (min-width:768px) {
    .vu-form textarea {
        padding: 1rem 1.25rem
    }
}

.vu-form textarea:focus {
    border-color: #0c71ac
}

@media screen and (min-width:768px) {
    .vu-form textarea {
        height: 4.6875rem
    }
}

.vu-form .radio-list label {
    align-items: center;
    display: inline-flex;
    margin-right: 1rem
}

.vu-form .radio-list input[type=radio]:not(.vu-option-button__radio) {
    margin-right: .5rem
}

@supports (-webkit-appearance:none) or (-moz-appearance:none) {
    .vu-form input[type=radio]:not(.vu-option-button__radio) {
        align-items: center;
        display: flex;
        justify-content: center;
        border: 1px solid #494949;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #eee;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .08);
        outline: 0
    }
    @media screen and (min-width:576px) {
        .vu-form input[type=radio]:not(.vu-option-button__radio) {
            width: 20px;
            height: 20px
        }
    }
    .vu-form input[type=radio]:not(.vu-option-button__radio):before {
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        content: "";
        transition: background-color .2s ease
    }
    .vu-form input[type=radio]:not(.vu-option-button__radio):checked {
        border: 1px solid #1e1248
    }
    .vu-form input[type=radio]:not(.vu-option-button__radio):checked:before {
        background-color: #1e1248
    }
    .vu-form input[type=radio]:not(.vu-option-button__radio):focus {
        border-color: #5bc2e7
    }
}

.vu-form .buttons button,
.vu-form [type=submit] {
    display: inline-block;
    padding: .625rem 1.875rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer
}

.vu-form .hint {
    margin-bottom: .5rem
}

.vu-form .error .form-control,
.vu-form .error .multiselect__tags,
.vu-form .error input,
.vu-form .error textarea {
    border-color: #d6001d
}

.vu-form .help-block {
    margin-top: .75rem
}

.vu-form .help-block.errors {
    order: 2;
    margin-bottom: .5rem;
    color: #d6001d
}

.vue-form-generator>fieldset {
    border-width: 0;
    padding: 0
}

.vue-form-generator .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem
}

@media screen and (min-width:768px) {
    .vue-form-generator .form-group {
        margin-bottom: 1.75rem
    }
}

.vue-form-generator .form-group.required .field-wrap>label:after,
.vue-form-generator .form-group.required>label:after {
    margin-left: .25rem;
    color: #d6001d;
    content: " (Required)"
}

.vue-form-generator .form-group label {
    order: 1
}

.vue-form-generator .form-group .hint {
    order: 2
}

.vue-form-generator .form-group .field-wrap {
    order: 3
}

.multiselect__tags {
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #b7b7b7;
    border-radius: .25rem;
    width: 100%;
    height: auto;
    background-color: #fff;
    color: #262626;
    padding: 1rem 2.5rem 1rem 1rem
}

.multiselect__tags:focus {
    outline: 2px solid #0c71ac;
    outline-offset: -2px
}

.multiselect__tags:focus:not(.focus-visible),
.multiselect__tags:focus:not(:focus-visible) {
    outline: none
}

.multiselect__tags::-moz-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.multiselect__tags:-ms-input-placeholder {
    color: #6e6e6e;
    opacity: 1
}

.multiselect__tags::placeholder {
    color: #6e6e6e;
    opacity: 1
}

@media screen and (min-width:768px) {
    .multiselect__tags {
        padding: 1rem 1.25rem
    }
}

.multiselect__tags:focus {
    border-color: #0c71ac
}

.multiselect__placeholder {
    margin-bottom: 0;
    padding: 0;
    color: #6e6e6e;
    opacity: 1
}

.multiselect__single {
    margin-bottom: 0;
    background: none
}

.multiselect__element:nth-child(2n),
.multiselect__element:nth-child(odd) {
    background-color: #fff
}

.multiselect__option--selected {
    color: #1e1248
}

.multiselect__option--highlight {
    background: #1e1248;
    color: #fff
}

.multiselect__option--highlight.multiselect__option--selected {
    background-color: #d6001d
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes vu-field-submit-loader-pulse-animation {
    0% {
        fill: #fff
    }
    12.5% {
        fill: #1e1248
    }
    25% {
        fill: #fff
    }
    to {
        fill: #fff
    }
}

@keyframes vu-field-submit-loader-pulse-animation {
    0% {
        fill: #fff
    }
    12.5% {
        fill: #1e1248
    }
    25% {
        fill: #fff
    }
    to {
        fill: #fff
    }
}

.vu-submit-loader {
    position: relative
}

.vu-submit-loader__text--loading {
    opacity: 0
}

.vu-submit-loader svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto
}

.vu-submit-loader svg path:first-child {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(2) {
    -webkit-animation-delay: .25s;
    animation-delay: .25s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(3) {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(4) {
    -webkit-animation-delay: .75s;
    animation-delay: .75s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(5) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(6) {
    -webkit-animation-delay: 1.25s;
    animation-delay: 1.25s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(7) {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

.vu-submit-loader svg path:nth-child(8) {
    -webkit-animation-delay: 1.75s;
    animation-delay: 1.75s;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: vu-field-submit-loader-pulse-animation;
    animation-name: vu-field-submit-loader-pulse-animation
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-grid-container {
    margin: 0 auto;
    width: 100%
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-container {
        display: table
    }
}

.vu-grid-container__inner {
    box-sizing: border-box;
    max-width: 94rem
}

@media screen and (min-width:0) {
    .vu-grid-container__inner {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:576px) {
    .vu-grid-container__inner {
        padding-right: 2.125rem;
        padding-left: 2.125rem
    }
}

@media screen and (min-width:768px) {
    .vu-grid-container__inner {
        padding-right: 2.5rem;
        padding-left: 2.5rem
    }
}

@media screen and (min-width:992px) {
    .vu-grid-container__inner {
        padding-right: 2rem;
        padding-left: 2rem
    }
}

@media screen and (min-width:1264px) {
    .vu-grid-container__inner {
        padding-right: 3rem;
        padding-left: 3rem
    }
}

@media screen and (min-width:1920px) {
    .vu-grid-container__inner {
        padding-right: 3rem;
        padding-left: 3rem
    }
}

@supports (display:grid) {
    .vu-grid-container__inner {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 1fr;
        margin: 0
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-container__inner {
            grid-template-columns: repeat(6, 1fr)
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-container__inner {
            grid-template-columns: repeat(12, 1fr)
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-container__inner {
        align-items: stretch;
        display: flex;
        flex-flow: row wrap;
        margin: 0 -1rem -2rem;
        max-width: none
    }
}

.vu-grid-container:not(.vu-grid-container--full-width) {
    max-width: 94rem
}

.vu-grid-container--no-outer-gutters:not(.vu-grid-container--full-width) {
    max-width: 92rem
}

.vu-grid-container--no-outer-gutters .vu-grid-container__inner {
    padding: 0
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-container--no-outer-gutters .vu-grid-container__inner {
        width: calc(100% + 2rem)
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-grid-cell--1,
.vu-grid-cell--2,
.vu-grid-cell--3,
.vu-grid-cell--4,
.vu-grid-cell--5,
.vu-grid-cell--6,
.vu-grid-cell--7,
.vu-grid-cell--8,
.vu-grid-cell--9,
.vu-grid-cell--10,
.vu-grid-cell--11,
.vu-grid-cell--12 {
    box-sizing: border-box
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--1,
    .vu-grid-cell--2,
    .vu-grid-cell--3,
    .vu-grid-cell--4,
    .vu-grid-cell--5,
    .vu-grid-cell--6,
    .vu-grid-cell--7,
    .vu-grid-cell--8,
    .vu-grid-cell--9,
    .vu-grid-cell--10,
    .vu-grid-cell--11,
    .vu-grid-cell--12 {
        flex-grow: 0;
        flex-shrink: 1;
        margin: 0 1rem 2rem
    }
}

@supports (display:grid) {
    .vu-grid-cell--1 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--1 {
            grid-column: span 1
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--1 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--1 {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--1 {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--2 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--2 {
            grid-column: span 2
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--2 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--2 {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--2 {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--3 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--3 {
            grid-column: span 3
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--3 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--3 {
        flex-basis: calc(50% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--3 {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--4 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--4 {
            grid-column: span 4
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--4 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--4 {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--4 {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--5 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--5 {
            grid-column: span 5
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--5 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--5 {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--5 {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--6 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--6 {
            grid-column: span 6
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--6 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--6 {
        flex-basis: calc(100% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--6 {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--7 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--7 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--7 {
            grid-column: span 7
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--7 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--7 {
        flex-basis: calc(116.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--7 {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--8 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--8 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--8 {
            grid-column: span 8
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--8 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--8 {
        flex-basis: calc(133.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--8 {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--9 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--9 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--9 {
            grid-column: span 9
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--9 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--9 {
        flex-basis: calc(150% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--9 {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--10 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--10 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--10 {
            grid-column: span 10
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--10 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--10 {
        flex-basis: calc(166.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--10 {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--11 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--11 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--11 {
            grid-column: span 11
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--11 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--11 {
        flex-basis: calc(183.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--11 {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    .vu-grid-cell--12 {
        grid-column: span 1
    }
    @media screen and (min-width:576px) and (max-width:991px) {
        .vu-grid-cell--12 {
            grid-column: span 6
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--12 {
            grid-column: span 12
        }
    }
}

@media (-ms-high-contrast:none),
screen and (-ms-high-contrast:active) {
    .vu-grid-cell--12 {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--12 {
        flex-basis: calc(200% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--12 {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--1\@xs-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--1\@xs-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--2\@xs-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--2\@xs-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--3\@xs-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--3\@xs-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--4\@xs-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--4\@xs-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--5\@xs-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--5\@xs-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--6\@xs-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--6\@xs-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--7\@xs-up {
            grid-column: span 7
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--7\@xs-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--8\@xs-up {
            grid-column: span 8
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--8\@xs-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--9\@xs-up {
            grid-column: span 9
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--9\@xs-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--10\@xs-up {
            grid-column: span 10
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--10\@xs-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--11\@xs-up {
            grid-column: span 11
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--11\@xs-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@xs-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:0) {
        .vu-grid-cell--12\@xs-up {
            grid-column: span 12
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@xs-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:0),
screen and (-ms-high-contrast:none) and (min-width:0) {
    .vu-grid-cell--12\@xs-up {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--1\@s-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--1\@s-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--1\@s-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--2\@s-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--2\@s-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--2\@s-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--3\@s-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--3\@s-up {
        flex-basis: calc(50% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--3\@s-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--4\@s-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--4\@s-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--4\@s-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--5\@s-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--5\@s-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--5\@s-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--6\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--6\@s-up {
        flex-basis: calc(100% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--6\@s-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--7\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--7\@s-up {
        flex-basis: calc(116.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--7\@s-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--8\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--8\@s-up {
        flex-basis: calc(133.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--8\@s-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--9\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--9\@s-up {
        flex-basis: calc(150% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--9\@s-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--10\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--10\@s-up {
        flex-basis: calc(166.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--10\@s-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--11\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--11\@s-up {
        flex-basis: calc(183.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--11\@s-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@s-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:576px) {
        .vu-grid-cell--12\@s-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@s-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:576px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:576px) and (max-width:991px) {
    .vu-grid-cell--12\@s-up {
        flex-basis: calc(200% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--12\@s-up {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--1\@m-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--1\@m-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--1\@m-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--2\@m-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--2\@m-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--2\@m-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--3\@m-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--3\@m-up {
        flex-basis: calc(50% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--3\@m-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--4\@m-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--4\@m-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--4\@m-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--5\@m-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--5\@m-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--5\@m-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--6\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--6\@m-up {
        flex-basis: calc(100% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--6\@m-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--7\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--7\@m-up {
        flex-basis: calc(116.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--7\@m-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--8\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--8\@m-up {
        flex-basis: calc(133.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--8\@m-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--9\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--9\@m-up {
        flex-basis: calc(150% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--9\@m-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--10\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--10\@m-up {
        flex-basis: calc(166.66667% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--10\@m-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--11\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--11\@m-up {
        flex-basis: calc(183.33333% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--11\@m-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@m-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:768px) {
        .vu-grid-cell--12\@m-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@m-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:768px) and (max-width:991px),
screen and (-ms-high-contrast:none) and (min-width:768px) and (max-width:991px) {
    .vu-grid-cell--12\@m-up {
        flex-basis: calc(200% - 2rem)
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--12\@m-up {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--1\@l-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--1\@l-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--2\@l-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--2\@l-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--3\@l-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--3\@l-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--4\@l-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--4\@l-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--5\@l-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--5\@l-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--6\@l-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--6\@l-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--7\@l-up {
            grid-column: span 7
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--7\@l-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--8\@l-up {
            grid-column: span 8
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--8\@l-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--9\@l-up {
            grid-column: span 9
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--9\@l-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--10\@l-up {
            grid-column: span 10
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--10\@l-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--11\@l-up {
            grid-column: span 11
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--11\@l-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@l-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:992px) {
        .vu-grid-cell--12\@l-up {
            grid-column: span 12
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@l-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:992px),
screen and (-ms-high-contrast:none) and (min-width:992px) {
    .vu-grid-cell--12\@l-up {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--1\@xl-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--1\@xl-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--2\@xl-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--2\@xl-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--3\@xl-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--3\@xl-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--4\@xl-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--4\@xl-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--5\@xl-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--5\@xl-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--6\@xl-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--6\@xl-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--7\@xl-up {
            grid-column: span 7
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--7\@xl-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--8\@xl-up {
            grid-column: span 8
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--8\@xl-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--9\@xl-up {
            grid-column: span 9
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--9\@xl-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--10\@xl-up {
            grid-column: span 10
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--10\@xl-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--11\@xl-up {
            grid-column: span 11
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--11\@xl-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@xl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1264px) {
        .vu-grid-cell--12\@xl-up {
            grid-column: span 12
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@xl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1264px),
screen and (-ms-high-contrast:none) and (min-width:1264px) {
    .vu-grid-cell--12\@xl-up {
        flex-basis: calc(100% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--1\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--1\@xxl-up {
            grid-column: span 1
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--1\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--1\@xxl-up {
        flex-basis: calc(8.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--2\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--2\@xxl-up {
            grid-column: span 2
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--2\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--2\@xxl-up {
        flex-basis: calc(16.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--3\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--3\@xxl-up {
            grid-column: span 3
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--3\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--3\@xxl-up {
        flex-basis: calc(25% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--4\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--4\@xxl-up {
            grid-column: span 4
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--4\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--4\@xxl-up {
        flex-basis: calc(33.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--5\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--5\@xxl-up {
            grid-column: span 5
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--5\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--5\@xxl-up {
        flex-basis: calc(41.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--6\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--6\@xxl-up {
            grid-column: span 6
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--6\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--6\@xxl-up {
        flex-basis: calc(50% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--7\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--7\@xxl-up {
            grid-column: span 7
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--7\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--7\@xxl-up {
        flex-basis: calc(58.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--8\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--8\@xxl-up {
            grid-column: span 8
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--8\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--8\@xxl-up {
        flex-basis: calc(66.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--9\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--9\@xxl-up {
            grid-column: span 9
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--9\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--9\@xxl-up {
        flex-basis: calc(75% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--10\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--10\@xxl-up {
            grid-column: span 10
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--10\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--10\@xxl-up {
        flex-basis: calc(83.33333% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--11\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--11\@xxl-up {
            grid-column: span 11
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--11\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--11\@xxl-up {
        flex-basis: calc(91.66667% - 2rem)
    }
}

@supports (display:grid) {
    @media screen and (max-width: 575px) {
        .vu-grid-cell--12\@xxl-up {
            grid-column: span 1
        }
    }
    @media screen and (min-width:1920px) {
        .vu-grid-cell--12\@xxl-up {
            grid-column: span 12
        }
    }
}

@media screen and (-ms-high-contrast:active) and (max-width:575px),
screen and (-ms-high-contrast:none) and (max-width:575px) {
    .vu-grid-cell--12\@xxl-up {
        flex-basis: 100%
    }
}

@media screen and (-ms-high-contrast:active) and (min-width:1920px),
screen and (-ms-high-contrast:none) and (min-width:1920px) {
    .vu-grid-cell--12\@xxl-up {
        flex-basis: calc(100% - 2rem)
    }
}

.vu-child-component-error {
    display: none
}

.vu-dev-mode .vu-child-component-error {
    display: block
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-navigation-level-2__link {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: none;
    margin: .25rem 0;
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-navigation-level-2__link {
        margin: .5rem 0;
        font-size: 1.25rem
    }
}

.vu-navigation-level-2 {
    position: relative;
    z-index: 11;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .07)
}

.vu-navigation-level-2>.vu-container {
    display: flex;
    justify-content: space-between
}

.vu-navigation-level-2__links {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none
}

.vu-navigation-level-2__link {
    display: flex;
    margin: 0
}

.vu-navigation-level-2__link>button {
    display: block;
    margin: 0 6px;
    border: 0;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    padding: 2rem;
    background: none;
    cursor: pointer
}

.vu-navigation-level-2__link>button .vu-text-icon {
    color: #262626
}

.vu-navigation-level-2__link>button .vu-icon {
    transition: transform .25s
}

.vu-navigation-level-2__link>button:disabled {
    cursor: auto;
    opacity: .5
}

.vu-navigation-level-2__link--active>button,
.vu-navigation-level-2__link--open>button,
.vu-navigation-level-2__link:focus>button,
.vu-navigation-level-2__link:hover>button {
    border-bottom-color: #0c71ac;
    color: #0c71ac
}

.vu-navigation-level-2__link--open>button .vu-icon {
    transform: rotate(180deg)
}

.vu-navigation-level-2__logo {
    margin: 1.25rem 0
}

.vu-navigation-level-2__logo img {
    height: 3.5rem
}

@media screen and (max-width:991px) {
    .vu-navigation-level-2 {
        display: none
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-page-builder__header-component {
    position: relative
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-header__top {
    padding: 1rem 0 1.5rem
}

@media screen and (min-width:992px) {
    .vu-header__top {
        padding: 1.5rem 0 2.5rem
    }
}

.vu-header__bottom {
    position: relative
}

.vu-header__bottom:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 50%;
    left: 0;
    content: ""
}

.vu-header--primary .vu-header__bottom:before,
.vu-header--primary .vu-header__top {
    background: #5bc2e7;
    color: #262626
}

.vu-header--primary-dark .vu-header__bottom:before,
.vu-header--primary-dark .vu-header__top {
    background: #0c71ac;
    color: #fff
}

.vu-header--primary-dark .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--primary-light .vu-header__bottom:before,
.vu-header--primary-light .vu-header__top {
    background: #eff9fd;
    color: #262626
}

.vu-header--black .vu-header__bottom:before,
.vu-header--black .vu-header__top {
    background: #000;
    color: #fff
}

.vu-header--black .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--black-85 .vu-header__bottom:before,
.vu-header--black-85 .vu-header__top {
    background: #4d4d4f;
    color: #fff
}

.vu-header--black-85 .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--black-55 .vu-header__bottom:before,
.vu-header--black-55 .vu-header__top {
    background: #8a8c8e;
    color: #262626
}

.vu-header--white .vu-header__bottom:before,
.vu-header--white .vu-header__top {
    background: #fff;
    color: #262626
}

.vu-header--aubergine .vu-header__bottom:before,
.vu-header--aubergine .vu-header__top {
    background: #1e1248;
    color: #fff
}

.vu-header--aubergine .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--aubergine-75 .vu-header__bottom:before,
.vu-header--aubergine-75 .vu-header__top {
    background: #544b73;
    color: #fff
}

.vu-header--aubergine-75 .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--grey-secondary .vu-header__bottom:before,
.vu-header--grey-secondary .vu-header__top {
    background: #ececec;
    color: #262626
}

.vu-header--yellow .vu-header__bottom:before,
.vu-header--yellow .vu-header__top {
    background: #fed76f;
    color: #262626
}

.vu-header--melon .vu-header__bottom:before,
.vu-header--melon .vu-header__top {
    background: #ed6b5e;
    color: #262626
}

.vu-header--melon-85 .vu-header__bottom:before,
.vu-header--melon-85 .vu-header__top {
    background: #f6958a;
    color: #262626
}

.vu-header--grape .vu-header__bottom:before,
.vu-header--grape .vu-header__top {
    background: #b49ad2;
    color: #262626
}

.vu-header--tango .vu-header__bottom:before,
.vu-header--tango .vu-header__top {
    background: #f79554;
    color: #262626
}

.vu-header--grey-dark .vu-header__bottom:before,
.vu-header--grey-dark .vu-header__top {
    background: #494949;
    color: #fff
}

.vu-header--grey-dark .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--grey-medium .vu-header__bottom:before,
.vu-header--grey-medium .vu-header__top {
    background: #b7b7b7;
    color: #262626
}

.vu-header--grey-light .vu-header__bottom:before,
.vu-header--grey-light .vu-header__top {
    background: #eee;
    color: #262626
}

.vu-header--grey-light-on-light .vu-header__bottom:before,
.vu-header--grey-light-on-light .vu-header__top {
    background: #6e6e6e;
    color: #fff
}

.vu-header--grey-light-on-light .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--grey-extra-light .vu-header__bottom:before,
.vu-header--grey-extra-light .vu-header__top {
    background: #f7f7f7;
    color: #262626
}

.vu-header--negative .vu-header__bottom:before,
.vu-header--negative .vu-header__top {
    background: #d6001d;
    color: #fff
}

.vu-header--negative .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--positive .vu-header__bottom:before,
.vu-header--positive .vu-header__top {
    background: #498406;
    color: #fff
}

.vu-header--positive .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--text .vu-header__bottom:before,
.vu-header--text .vu-header__top {
    background: #262626;
    color: #fff
}

.vu-header--text .vu-breadcrumbs__link {
    color: #b7b7b7
}

.vu-header--vic-poly .vu-header__bottom:before,
.vu-header--vic-poly .vu-header__top {
    background: #1da593;
    color: #262626
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-breadcrumbs {
    margin: 0 0 3rem
}

@media print {
    .vu-breadcrumbs {
        display: none
    }
}

.vu-breadcrumbs__items {
    display: block;
    margin: .5rem 0 0;
    padding: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    list-style: none
}

.vu-breadcrumbs__item {
    display: inline-block;
    margin: 0 .5rem .5rem 0
}

.vu-breadcrumbs__item--last {
    display: none
}

.vu-breadcrumbs__link {
    color: #494949
}

@media screen and (min-width:992px) {
    .vu-breadcrumbs {
        margin-bottom: 3.5rem
    }
    .vu-breadcrumbs__items {
        margin-top: 0
    }
    .vu-breadcrumbs__item--last {
        display: inline-block
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-tabs {
    width: 100%;
    background: #000
}

@media screen and (min-width:768px) and (max-width:991px) {
    .vu-tabs {
        margin-right: -2.5rem;
        margin-left: -2.5rem;
        width: calc(100% + 5rem)
    }
}

@media screen and (min-width:576px) and (max-width:767px) {
    .vu-tabs {
        margin-right: -2.125rem;
        margin-left: -2.125rem;
        width: calc(100% + 4.25rem)
    }
}

@media screen and (min-width:0) and (max-width:575px) {
    .vu-tabs {
        margin-right: -1rem;
        margin-left: -1rem;
        width: calc(100% + 2rem)
    }
}

.vu-tabs__inner {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    margin: 0 auto
}

@media screen and (max-width:991px) {
    .vu-tabs__inner.vu-tabs-enter-active,
    .vu-tabs__inner.vu-tabs-leave-active {
        overflow: hidden;
        transition: height .5s
    }
    .vu-tabs__inner.vu-tabs-enter,
    .vu-tabs__inner.vu-tabs-leave-to {
        height: 0!important
    }
}

@media screen and (min-width:992px) {
    .vu-tabs__inner {
        position: static;
        display: flex
    }
}

.vu-tabs__icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 .5rem;
    width: 2rem;
    height: 2rem;
    background: #eee;
    color: #000;
    transform: rotate(45deg)
}

.vu-tabs__icon .vu-icon {
    transform: rotate(-45deg)
}

.vu-tabs__select {
    align-items: center;
    display: flex;
    justify-content: center;
    border: 0;
    padding: 1.5rem .5rem;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    color: #fff;
    cursor: pointer
}

.vu-tabs__select .vu-tabs__icon {
    background: #5bc2e7
}

.vu-tabs__select .vu-tabs__icon+.vu-tabs__text {
    margin-left: .75rem
}

@media screen and (min-width:992px) {
    .vu-tabs__select {
        display: none
    }
}

.vu-tabs__tab {
    display: block;
    flex: 1 1 0px;
    padding: 1rem .5rem;
    text-align: center;
    text-decoration: none;
    background: #000;
    color: #fff
}

.vu-tabs__tab--active,
.vu-tabs__tab:hover {
    background: #5bc2e7;
    color: #000
}

.vu-tabs__tab--active .vu-tabs__icon,
.vu-tabs__tab:hover .vu-tabs__icon {
    background: #5bc2e7
}

@media screen and (min-width:992px) {
    .vu-tabs__tab {
        padding-top: 1.5rem;
        color: #b7b7b7
    }
    .vu-tabs__tab--active,
    .vu-tabs__tab:hover {
        background: #fff;
        color: #000
    }
    .vu-tabs__tab:not(:first-of-type) {
        border-left: 1px solid #eee
    }
}

.vu-tabs__text {
    margin: .5rem 0;
    font-weight: 600
}

@media screen and (min-width:992px) {
    .vu-tabs__text {
        margin-bottom: 0
    }
}

.vu-container>.vu-tabs--dropdown {
    margin: 0 -1rem
}

.vu-tabs--sticky {
    position: fixed;
    top: 3rem;
    right: 0;
    left: 0;
    z-index: 2
}

.vu-tabs--sticky .vu-tabs__inner {
    max-width: 88rem
}

@media screen and (min-width:992px) and (max-width:1263px) {
    .vu-tabs--sticky .vu-tabs__tab {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1.5rem .5rem
    }
    .vu-tabs--sticky .vu-tabs__text {
        margin: 0 0 0 1.25rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@media screen and (max-width:767px) {
    .tide-content--page-builder-layout {
        background: #f7f7f7
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-success-story-list {
    margin: 1.5rem 0 3.5rem
}

.vu-success-story-list .vu-button,
.vu-success-story-list__end-of-results {
    margin: 2rem 0 0
}

@media screen and (min-width:768px) {
    .vu-success-story-list .vu-button,
    .vu-success-story-list__end-of-results {
        margin-top: 2.5rem
    }
}

@media screen and (min-width:992px) {
    .vu-success-story-list .vu-button,
    .vu-success-story-list__end-of-results {
        margin: 2.5rem auto 0
    }
}

.vu-success-story-list__end-of-results {
    text-align: center
}

.vu-success-story-list__no-results hr {
    margin: 2rem 0 1.5rem
}

.vu-success-story-list .vu-button {
    display: block;
    padding: 1rem;
    width: 100%;
    font-size: 1.5rem;
    line-height: 2rem
}

@media screen and (min-width:992px) {
    .vu-success-story-list .vu-button {
        padding: .75rem 2rem;
        width: auto;
        font-size: 1.25rem;
        line-height: 1.5rem
    }
}

@media screen and (min-width:768px) {
    .vu-success-story-list .vu-success-story-card__icon {
        bottom: -1.0625rem;
        width: 2.125rem;
        height: 2.125rem
    }
    .vu-success-story-list .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.8)
    }
}

@media screen and (min-width:992px) and (max-width:1263px) {
    .vu-success-story-list .vu-success-story-card__icon {
        bottom: -.875rem;
        width: 1.75rem;
        height: 1.75rem
    }
    .vu-success-story-list .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.65)
    }
}

@media screen and (min-width:1920px) {
    .vu-success-story-list .vu-success-story-card__icon {
        bottom: -1.0625rem;
        width: 2.125rem;
        height: 2.125rem
    }
    .vu-success-story-list .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.8)
    }
}

.vu-success-story-list .vu-success-story-card:visited {
    text-decoration: none;
    color: #262626
}

.vu-success-story-list .vu-success-story-card:hover .vu-success-story-card__name {
    text-decoration: underline;
    color: #0c71ac
}

@media screen and (max-width:767px) {
    .vu-success-story-list .vu-success-story-card:hover {
        background: #5bc2e7
    }
    .vu-success-story-list .vu-success-story-card:hover .vu-success-story-card__name {
        color: #1e1248
    }
}

@media screen and (max-width:767px) {
    .vu-success-story-list .vu-success-story-card {
        align-items: center;
        display: flex;
        margin: 0;
        width: 100%;
        background: #fff
    }
    .vu-success-story-list .vu-success-story-card__content {
        padding: 0 1rem
    }
    .vu-success-story-list .vu-success-story-card__image-wrapper {
        align-items: center;
        display: flex;
        flex: 0 0 6.25rem;
        height: 6.25rem;
        background: #000
    }
    .vu-success-story-list .vu-success-story-card__icon {
        right: .5rem;
        bottom: .5rem;
        width: 1.25rem;
        height: 1.25rem
    }
    .vu-success-story-list .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.5)
    }
    .vu-success-story-list .vu-success-story-card__image {
        display: none
    }
    .vu-success-story-list .vu-success-story-card__thumbnail {
        display: block
    }
}

.vu-success-story-list__items .vu-grid-cell--6 {
    display: flex
}

@media screen and (max-width:767px) {
    .vu-success-story-list__items .vu-grid-container__inner {
        grid-gap: .25rem
    }
}

@media screen and (min-width:768px) {
    .vu-success-story-list {
        margin-bottom: 7.5rem
    }
}

@media screen and (min-width:992px) {
    .vu-success-story-list {
        margin-top: 2rem
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-success-story-filter-list {
    position: relative
}

.vu-success-story-filter-list__link,
.vu-success-story-filter-list__title {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: none
}

.vu-success-story-filter-list__link {
    display: block;
    padding: 1.25rem 1rem;
    text-decoration: none;
    color: #262626
}

.vu-success-story-filter-list__link:hover {
    text-decoration: underline
}

@media screen and (min-width:992px) {
    .vu-success-story-filter-list__link {
        padding: .5rem 1rem
    }
}

.vu-success-story-filter-list__title {
    display: none;
    margin: 1.25rem 1rem
}

@media screen and (min-width:992px) {
    .vu-success-story-filter-list__title {
        display: block;
        margin: .5rem 1rem;
        font-weight: 600
    }
}

.vu-success-story-filter-list__links {
    margin: 0;
    padding: 0;
    background: #fff;
    list-style: none
}

.vu-success-story-filter-list__links.vu-success-story-filter-list-enter-active,
.vu-success-story-filter-list__links.vu-success-story-filter-list-leave-active {
    overflow: hidden;
    transition: height .5s
}

.vu-success-story-filter-list__links.vu-success-story-filter-list-enter,
.vu-success-story-filter-list__links.vu-success-story-filter-list-leave-to {
    height: 0!important
}

@media screen and (max-width:991px) {
    .vu-success-story-filter-list__links {
        position: absolute;
        top: 100%;
        right: -1px;
        left: -1px;
        z-index: 1;
        overflow: auto;
        border: 1px solid #000;
        border-top: 0;
        max-height: 60vh
    }
    .vu-success-story-filter-list__links--unhydrated {
        display: none
    }
}

.vu-success-story-filter-list__link-wrapper {
    margin: 0 0 1px;
    background: #f7f7f7
}

.vu-success-story-filter-list__link-wrapper--active {
    background: #5bc2e7
}

.vu-success-story-filter-list__link-wrapper--active .vu-success-story-filter-list__link {
    text-decoration: underline
}

@media screen and (max-width:991px) {
    .vu-success-story-filter-list__link-wrapper--active {
        display: none
    }
}

.vu-success-story-filter-list__select {
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 0;
    padding: 0 1rem 0 0;
    width: 100%;
    text-align: left;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff;
    cursor: pointer
}

.vu-success-story-filter-list__select .vu-success-story-filter-list__title {
    display: block
}

@media screen and (min-width:992px) {
    .vu-success-story-filter-list__select {
        display: none
    }
}

@media screen and (max-width:991px) {
    .vu-success-story-filter-list {
        border: 1px solid #000
    }
    .vu-success-story-filter-list--sticky .vu-success-story-filter-list__wrapper {
        position: fixed;
        top: 130px;
        right: 0;
        left: 0;
        z-index: 1;
        padding: 1rem;
        background: #f7f7f7
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-success-story-card__name {
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-success-story-card__name {
        font-size: 1.25rem
    }
}

.vu-success-story-card__cta,
.vu-success-story-card__excerpt {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-success-story-card__cta,
    .vu-success-story-card__excerpt {
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.vu-success-story-card__course {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem
}

@media screen and (min-width:992px) {
    .vu-success-story-card__course {
        font-size: 1rem;
        line-height: 1.5rem
    }
}

.vu-success-story-card {
    display: block;
    color: #262626;
    transition: box-shadow .1s ease
}

.vu-success-story-card__excerpt {
    margin: 1.5rem 0;
    padding: 0
}

@media screen and (min-width:992px) {
    .vu-success-story-card__excerpt {
        margin-bottom: 2rem
    }
}

.vu-success-story-card__image-wrapper {
    position: relative
}

.vu-success-story-card__image {
    display: block;
    width: 100%
}

.vu-success-story-card__icon {
    position: absolute;
    right: 2rem;
    bottom: -1rem;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background: #5bc2e7;
    transform: rotate(45deg)
}

.vu-success-story-card__icon .vu-icon {
    transform: rotate(-45deg) scale(.6)
}

@media screen and (min-width:576px) {
    .vu-success-story-card__icon {
        bottom: -1.5rem;
        width: 3rem;
        height: 3rem
    }
    .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg)
    }
}

@media screen and (min-width:768px) {
    .vu-success-story-card__icon {
        bottom: -.75rem;
        width: 1.5rem;
        height: 1.5rem
    }
    .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.5)
    }
}

@media screen and (min-width:992px) {
    .vu-success-story-card__icon {
        right: 2.5rem;
        bottom: -1rem;
        width: 2rem;
        height: 2rem
    }
    .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.6)
    }
}

@media screen and (min-width:1264px) {
    .vu-success-story-card__icon {
        bottom: -1.25rem;
        width: 2.5rem;
        height: 2.5rem
    }
    .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg) scale(.8)
    }
}

@media screen and (min-width:1920px) {
    .vu-success-story-card__icon {
        bottom: -1.5rem;
        width: 3rem;
        height: 3rem
    }
    .vu-success-story-card__icon .vu-icon {
        transform: rotate(-45deg)
    }
}

.vu-success-story-card__content {
    padding: 1.5rem 1.5rem 2rem
}

@media screen and (min-width:992px) {
    .vu-success-story-card__content {
        padding: 2rem 2rem 3rem
    }
}

.vu-success-story-card__course,
.vu-success-story-card__name {
    margin: 0
}

.vu-success-story-card__name {
    font-weight: 600
}

.vu-success-story-card__course {
    color: #6e6e6e
}

.vu-success-story-card__cta {
    color: #0c71ac
}

.vu-success-story-card__thumbnail {
    display: none;
    width: 100%
}

.vu-success-story-card--primary-light {
    background: #eff9fd
}

.vu-success-story-card--grey-extra-light {
    background: #f7f7f7
}

.vu-success-story-card--white {
    background: #fff
}

.vu-success-story-card:hover {
    box-shadow: 0 .5rem 2rem 0 rgba(0, 0, 0, .2)
}

.vu-success-story-card:hover .vu-success-story-card__cta {
    text-decoration: underline
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-footer a,
.vu-footer p {
    font-size: 1rem;
    line-height: 1.5rem
}

.vu-footer a {
    text-decoration: none
}

.vu-footer a:focus,
.vu-footer a:hover {
    text-decoration: underline
}

.vu-footer__legal,
.vu-footer__middle {
    background: #000
}

.vu-footer__legal,
.vu-footer__legal .vu-markup,
.vu-footer__middle,
.vu-footer__middle .vu-markup {
    color: #fff
}

.vu-footer__legal a,
.vu-footer__middle a {
    color: #b7b7b7
}

.vu-footer__legal a:focus,
.vu-footer__legal a:hover,
.vu-footer__middle a:focus,
.vu-footer__middle a:hover {
    color: #fff
}

.vu-footer__middle {
    padding: 2.5rem 0
}

.vu-footer__middle h2,
.vu-footer__middle h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    text-transform: none
}

.vu-footer__middle h2 {
    margin: 0 0 1rem;
    font-weight: 600
}

.vu-footer__middle h3 {
    margin: 0
}

.vu-footer__middle .vu-footer-column__title-group {
    margin: 0 0 1rem
}

.vu-footer__middle .vu-footer-column__link-heading,
.vu-footer__middle .vu-footer-column__subtitle,
.vu-footer__middle .vu-footer-column__title {
    margin: 0
}

.vu-footer__middle .vu-list {
    margin: .5rem 0 2.5rem
}

@media screen and (min-width:992px) {
    .vu-footer__middle .vu-list {
        margin-bottom: 1rem
    }
}

.vu-footer__legal {
    padding: 1.25rem 0;
    text-align: center
}

.vu-footer__legal a,
.vu-footer__legal p {
    font-size: .875rem;
    line-height: 1.25rem
}

.vu-footer__legal-menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 1.5rem 0 1rem
}

.vu-footer__legal-menu .vu-list__item {
    margin: .75rem 1rem
}

@media screen and (min-width:992px) {
    .vu-footer__legal-menu {
        margin: 0
    }
    .vu-footer__legal-menu .vu-list__item {
        margin: 1.25rem 1rem 0
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-footer__menu .vu-accordion__title-text,
.vu-footer__menu .vu-accordion__title .vu-link,
.vu-footer__menu .vu-footer-column__link-heading,
.vu-footer__menu .vu-footer-column__title {
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
    text-transform: uppercase
}

.vu-footer__menu .vu-footer-column__subtitle {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: none
}

.vu-footer__menu .vu-footer-column__link-heading,
.vu-footer__menu .vu-footer-column__title {
    margin: .5rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-footer__menu .vu-footer-column__link-heading,
    .vu-footer__menu .vu-footer-column__title {
        margin: 1rem 0;
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.vu-footer__menu .vu-footer-column__subtitle {
    margin: .25rem 0;
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-footer__menu .vu-footer-column__subtitle {
        margin: .5rem 0;
        font-size: 1.25rem
    }
}

.vu-footer__menu {
    background: #f7f7f7
}

.vu-footer__menu .vu-accordion,
.vu-footer__menu .vu-footer-column__link-heading,
.vu-footer__menu .vu-footer-column__subtitle,
.vu-footer__menu .vu-footer-column__title {
    margin: 0
}

.vu-footer__menu .vu-accordion__button {
    align-items: center;
    display: flex;
    justify-items: center;
    border-left: 1px solid #b7b7b7;
    padding: 1.5rem;
    background: #fff
}

.vu-footer__menu .vu-accordion__button--expanded,
.vu-footer__menu .vu-accordion__button:focus,
.vu-footer__menu .vu-accordion__button:hover {
    background: #5bc2e7
}

.vu-footer__menu .vu-accordion__button:hover {
    outline: none
}

.vu-footer__menu .vu-accordion__icon {
    margin: 0;
    color: #262626
}

.vu-footer__menu .vu-accordion__title {
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #b7b7b7;
    padding: 0
}

.vu-footer__menu .vu-accordion__title>div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 1.5rem 0 1.5rem 1.5rem
}

.vu-footer__menu .vu-accordion__title .vu-link {
    font-size: 1.125rem;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-footer__menu .vu-accordion__title .vu-link {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.vu-footer__menu .vu-accordion__title-text {
    display: block;
    font-size: 1.125rem;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-footer__menu .vu-accordion__title-text {
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.vu-footer__menu .vu-accordion .vu-list {
    margin: 0;
    padding: 1.5rem;
    background: #fff
}

.vu-footer__menu .vu-accordion .vu-list__item {
    margin: 1.5rem 0
}

.vu-footer__menu .vu-accordion .vu-list__item:first-of-type {
    margin-top: 0
}

.vu-footer__menu .vu-accordion .vu-list__item:last-of-type {
    margin-bottom: 0
}

.vu-footer__menu .vu-link {
    color: #0c71ac
}

.vu-footer__menu .vu-list__item {
    margin: .5rem 0
}

@media screen and (min-width:992px) {
    .vu-footer__menu {
        padding: 2.5rem 0
    }
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-accordion__button-text {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: none;
    margin: .25rem 0;
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-accordion__button-text {
        margin: .5rem 0;
        font-size: 1.25rem
    }
}

.vu-accordion__button {
    display: block;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer
}

.vu-accordion__button:hover {
    outline: 1px dashed #0c71ac
}

.vu-accordion__button-text,
.vu-accordion__content {
    margin: 0
}

.vu-accordion__content.vu-accordion-enter-active,
.vu-accordion__content.vu-accordion-leave-active {
    overflow: hidden;
    transition: height .5s
}

.vu-accordion__content.vu-accordion-enter,
.vu-accordion__content.vu-accordion-leave-to {
    height: 0!important
}

.vu-accordion__content-inner {
    padding: 1.5rem 1.5rem 2rem
}

@media screen and (min-width:768px) {
    .vu-accordion__content-inner {
        padding-bottom: 2.5rem
    }
}

.vu-accordion__icon {
    margin-right: 1.5rem;
    transition: transform .25s
}

.vu-accordion__icon--expanded {
    transform: rotate(-180deg)
}

@media print {
    .vu-accordion__icon--expanded {
        transform: rotate(0deg)
    }
}

.vu-accordion__title {
    display: flex
}

.vu-accordion:not(.vu-accordion--separate-expanders) .vu-accordion__button {
    padding: 1rem 1.5rem;
    width: 100%;
    text-align: left
}

.vu-accordion--primary {
    border-top: 1px solid #b7b7b7
}

.vu-accordion--primary .vu-accordion__button:hover .vu-accordion__button-text {
    text-decoration: underline
}

.vu-accordion--primary .vu-accordion__button-text,
.vu-accordion--primary .vu-accordion__icon {
    color: #0c71ac
}

.vu-accordion--primary .vu-accordion__item {
    border-bottom: 1px solid #b7b7b7
}

.vu-accordion--secondary .vu-accordion__button {
    background: rgba(91, 194, 231, .1)
}

.vu-accordion--secondary .vu-accordion__button--expanded,
.vu-accordion--secondary .vu-accordion__button:hover {
    background: #5bc2e7
}

.vu-accordion--secondary .vu-accordion__button-text {
    color: #1e1248
}

.vu-accordion--secondary .vu-accordion__content {
    border: 1px solid #b7b7b7;
    border-top: 0
}

.vu-accordion--secondary .vu-accordion__icon {
    color: #1e1248
}

.vu-accordion--secondary .vu-accordion__item {
    margin: 0 0 .25rem
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-list {
    padding: 0;
    list-style: none
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-footer-column__app-store,
.vu-footer-column__social-links {
    display: flex;
    flex-flow: row wrap
}

.vu-footer-column__app-store-image {
    margin: 1rem .5rem 0 0
}

.vu-footer-column__app-store-image,
.vu-footer-column__app-store-image img {
    display: block
}

.vu-footer-column__app-store-image img {
    height: 2.5rem
}

.vu-footer-column__social-links .vu-link {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 2px 2px 0;
    width: 3.5rem;
    height: 3.5rem;
    background: #262626
}

.vu-footer-column__social-links .vu-link:focus,
.vu-footer-column__social-links .vu-link:hover {
    background: #fff
}

.vu-footer-column__social-links .vu-link:focus .vu-icon,
.vu-footer-column__social-links .vu-link:hover .vu-icon {
    color: #000
}

.vu-footer-column__social-links .vu-list__item {
    margin: 0
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-markup h2,
.vu-markup h3,
.vu-markup h4 {
    font-family: "din-condensed", sans-serif;
    font-weight: 400;
    text-transform: uppercase
}

.vu-markup h5,
.vu-markup h6 {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-transform: none
}

.vu-markup h2 {
    margin: 1rem 0;
    font-size: 1.75rem;
    line-height: 2.25rem
}

@media screen and (min-width:992px) {
    .vu-markup h2 {
        margin: 1.5rem 0;
        font-size: 2.75rem;
        line-height: 3.25rem
    }
}

.vu-markup h3 {
    margin: 1rem 0;
    font-size: 1.5rem;
    line-height: 2rem
}

@media screen and (min-width:992px) {
    .vu-markup h3 {
        font-size: 2rem;
        line-height: 2.5rem
    }
}

.vu-markup h4 {
    margin: .5rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-markup h4 {
        margin: 1rem 0;
        font-size: 1.5rem;
        line-height: 2rem
    }
}

.vu-markup h5 {
    margin: .25rem 0;
    font-size: 1.125rem;
    line-height: 1.75rem
}

@media screen and (min-width:992px) {
    .vu-markup h5 {
        margin: .5rem 0;
        font-size: 1.25rem
    }
}

.vu-markup h6 {
    margin: .25rem 0;
    font-size: 1rem;
    line-height: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-markup h6 {
        margin: .5rem 0;
        font-size: 1.125rem;
        line-height: 1.75rem
    }
}

.vu-markup {
    color: #262626
}

@media print {
    .vu-markup {
        color: #000
    }
}

.vu-markup blockquote,
.vu-markup p.paragraph--lead {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem
}

.vu-markup h2 {
    margin-top: 3.5rem;
    margin-bottom: 1rem
}

.vu-markup h3,
.vu-markup h4,
.vu-markup h5,
.vu-markup h6 {
    margin-top: 2rem;
    margin-bottom: 1rem
}

.vu-markup h2+h3 {
    margin-top: 2rem
}

.vu-markup h3+h4 {
    margin-top: 1.5rem
}

.vu-markup h4+h5,
.vu-markup h5+h6 {
    margin-top: 1rem
}

.vu-markup ol,
.vu-markup p,
.vu-markup ul {
    margin-top: 1rem;
    margin-bottom: 1rem
}

.vu-markup ol li:first-of-type,
.vu-markup p li:first-of-type,
.vu-markup ul li:first-of-type {
    margin-top: 1rem
}

.vu-markup li {
    margin-bottom: 1.5rem
}

.vu-markup li,
.vu-markup ol+p,
.vu-markup ul+p {
    margin-top: 1.5rem
}

@media screen and (min-width:992px) {
    .vu-markup blockquote,
    .vu-markup p.paragraph--lead {
        margin-top: 2rem;
        margin-bottom: 2rem
    }
    .vu-markup h2 {
        margin-top: 4.5rem;
        margin-bottom: 2rem
    }
    .vu-markup h3,
    .vu-markup h4 {
        margin-top: 2.5rem;
        margin-bottom: 1.5rem
    }
    .vu-markup h5,
    .vu-markup h6 {
        margin-top: 2rem;
        margin-bottom: 1rem
    }
    .vu-markup h2+h3 {
        margin-top: 2.5rem
    }
    .vu-markup h3+h4 {
        margin-top: 2rem
    }
    .vu-markup h4+h5 {
        margin-top: 1.5rem
    }
    .vu-markup h5+h6 {
        margin-top: 1rem
    }
    .vu-markup ol,
    .vu-markup p,
    .vu-markup ul {
        margin-top: 1rem;
        margin-bottom: 1rem
    }
    .vu-markup li {
        margin-top: .5rem;
        margin-bottom: .5rem
    }
    .vu-markup p+ol,
    .vu-markup p+ul {
        margin-top: -.5rem
    }
    .vu-markup p+ol li:first-of-type,
    .vu-markup p+ul li:first-of-type {
        margin-top: .5rem
    }
    .vu-markup ol+p,
    .vu-markup ul+p {
        margin-top: 1rem
    }
}

.vu-markup img {
    max-width: 100%;
    height: auto
}

.vu-markup ul {
    list-style-type: disc
}

.vu-markup ul ul {
    list-style-type: circle
}

.vu-markup ul ul ul {
    list-style-type: square
}

.vu-markup__inner>h2:first-child {
    margin-top: 0
}

.vu-markup a:not(.vu-button) .vu-text-label {
    color: #0c71ac
}

@media print {
    .vu-markup a:not(.vu-button) .vu-text-label {
        color: #000
    }
}

.vu-markup__iframe-container {
    position: relative;
    overflow: hidden;
    padding-top: 1rem;
    padding-bottom: 56.25%
}

.vu-markup__iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%
}

.vu-markup .wysiwyg-callout,
.vu-markup__callout {
    margin: 1.75rem auto;
    border-left: .25rem solid #b7b7b7;
    padding: 0 0 0 1.5rem;
    color: #262626
}

.vu-markup .media--type-image img,
.vu-markup__image img {
    max-width: 100%;
    height: auto
}

@media print {
    .vu-markup .media--type-image img,
    .vu-markup__image img {
        width: auto;
        height: 9.375rem
    }
}

.vu-markup .quotation,
.vu-markup__quotation {
    position: relative;
    margin-right: 0;
    margin-left: 0;
    padding: 0 0 0 1.5rem
}

@media screen and (min-width:576px) {
    .vu-markup .quotation,
    .vu-markup__quotation {
        padding: 0 0 0 2rem
    }
}

.vu-markup .quotation p,
.vu-markup__quotation p {
    margin: 1.25rem auto .5rem;
    color: #262626
}

.vu-markup .quotation p:after,
.vu-markup .quotation p:before,
.vu-markup__quotation p:after,
.vu-markup__quotation p:before {
    display: inline-block;
    border-right: .25rem solid #b7b7b7;
    border-left: .25rem solid #b7b7b7;
    width: .5rem;
    height: 1.5rem;
    content: ""
}

.vu-markup .quotation p:before,
.vu-markup__quotation p:before {
    position: absolute;
    left: 0
}

.vu-markup .quotation p:after,
.vu-markup__quotation p:after {
    vertical-align: middle;
    margin: auto auto -.5rem .5rem
}

.vu-markup .quotation__author,
.vu-markup .quotation__author-title,
.vu-markup__quotation__author,
.vu-markup__quotation__author-title {
    font-style: normal;
    text-transform: uppercase;
    color: #565656
}

.vu-markup .table-container,
.vu-markup__table {
    overflow: auto;
    border: 1px solid #b7b7b7;
    border-radius: .25rem;
    width: 100%;
    background-color: #fff;
    -webkit-overflow-scrolling: touch
}

.vu-markup .table-container table,
.vu-markup__table table {
    border-collapse: collapse;
    width: 100%
}

.vu-markup .table-container table caption,
.vu-markup__table table caption {
    vertical-align: top;
    padding: 1rem;
    text-align: left
}

.vu-markup .table-container table thead tr,
.vu-markup__table table thead tr {
    background-color: #eee
}

.vu-markup .table-container table tbody tr,
.vu-markup__table table tbody tr {
    background-color: #fff
}

.vu-markup .table-container table tbody tr:nth-child(2n),
.vu-markup__table table tbody tr:nth-child(2n) {
    background-color: #eee
}

.vu-markup .table-container table th,
.vu-markup__table table th {
    text-align: left
}

.vu-markup .table-container table td,
.vu-markup .table-container table th,
.vu-markup__table table td,
.vu-markup__table table th {
    vertical-align: top;
    padding: 1rem
}

.vu-markup__embedded-video .vu-embed-video__link {
    padding: 1rem 0
}

.vu-markup__embedded-video .vu-embed-video__transcript {
    text-transform: uppercase;
    color: #494949
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0
    }
    to {
        background-position: 1000px 0
    }
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

.vu-acknowledgement-of-country__flag {
    vertical-align: middle;
    margin: 0 .5rem 0 0;
    height: 1.5rem
}