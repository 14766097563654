// LOCK SCROLLING
html{
    overflow: hidden;
}
//SEARCH MODAL
.search-modal__wrapper{
    display: none;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    color: rgb(38, 38, 38);
    top: 0;
    left: 0;
    
    a{
        color: $color-black-mine_shaft !important;
        text-decoration: none !important;
    }
}

.search-modal__wrapper.inactive{
    // display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    // animation: fadeout 0.3s;
}

.search-modal__wrapper.active{
    display: block;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgb(0 0 0 / 25%);
    opacity: 1;
    transition: opacity 0.3s ease-in;
    animation: fadein 0.3s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1;}
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; visibility: hidden;
        }
}

.search-modal__container{
    width: 575px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, 0%);
    padding: 30px;
    right: 15px;
    box-shadow: 0 3px 9px rgb(0 0 0 / 25%);
    
}

.search-modal__wrapper.active .search-modal__container{
    transition: ease-in 0.3s;
    animation: slidedown 0.3s;
    transform: translate(-50%, -50%);
}

.search-modal__wrapper.inactive .search-modal__container{
    transition: ease-in 0.3s;
    animation: slidedup 0.3s;
    transform: translate(-50%, -50%);
}


@keyframes slidedown {
    from { transform: translate(-50%, -100%); }
    to   { transform: translate(-50%, -50%);}
}

@keyframes slideup {
    from   { transform: translate(-50%, -50%);}
    to { transform: translate(-50%, -100%); }
}

.search-modal__heading-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.search-modal__heading{
    font-size: 1.75rem;
    margin: 0;
    letter-spacing: -0.7px;
}

.search-modal__description{
    // line-height: 1.5rem;
    font-size: 1rem;
}

.search-modal__list-criteria{
    position: relative;
    top: 8px;
    margin-bottom: 0.8rem;
    padding-left: 13px;
    font-size: 1rem;
    list-style: none;

    li{
        margin: 0;
        // margin-top: 10px;
        // line-height: 1.9rem;
        margin-left: 6px;
    }

    li::before{
        content: '\25B8';
        vertical-align: top;
        padding-right: 16px;

    }
}

.search-modal__domestic{
    width: 100%;
    // padding-top: 5px;
}

.search-modal__international{
    width: 100%;
    padding-top: 14px;
}

#dismiss-modal-close-icon{
    background-color: white;
    border: 0;
}

.modal__close-button-icon{
    font-size: 36px;
    position: relative;
    top: -2px;
    left: -2px;
}

#dismiss-modal-close-text{
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.search-modal__more-info{
    padding-top: 10px;
    margin-bottom: 16px;
    font-size: 1rem;
    // line-height: 1.5rem;
}

.search-modal__external-link:link{
    text-decoration: none;
    letter-spacing: -0.1px;
    border-bottom: 1px solid #5bc2e7;
    // box-shadow: inset 0 -.45em .85em #5bc2e7;
    transition: box-shadow .3s;
    cursor: pointer;

    &:hover{
        box-shadow: inset 0 -1.15em 0 #5bc2e7;
    }
}

.search-modal__external-link-icon{
    font-size: 13px;
    margin-left: 5px;
    display: block;
    background-color: transparent;
    display: inline;
    position: relative;
}

.dismiss-modal{
    background-color: white;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    font-family: $fonts-default;
}