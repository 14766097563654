*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: $fonts-default;
    
}

button{
    cursor: pointer;
}

.vu-navigation-bar .vu-container{

    @media screen and (min-width: 990px) {
        display: none !important;
    }
}

.vu-accordion{
    z-index: 1 !important;
}

// COURSES & UNITS NAV MOBILE 
.search-people__wrapper {
    background-color: $color-grey-gallery;
    position: relative;

    .search-bar{
        max-width: 1410px;
        margin: 0 auto;
    }

    .search-bar__input-group{
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .search__form-container{
        width: 100%;
        max-width: 1410px;

        @media screen and (min-width: $desktop) {
            // height: 64px;
        }

    }

    .twitter-typeahead{
        width: 100%;
        // height: 72px;
    }

    .search-bar__input{
        width: 100%;
        height: 72px;
        border: none;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 18px;
        line-height: 28px;
        margin-top: 24px;
        margin-bottom: 24px;
        

        @media screen and (min-width: $desktop) {
            width: 100%;
            max-height: 64px;
            font-size: 20px;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    
    .search-layout__search-bar {
        background-color: $color-black;
        padding: 0 16px;
    }

    .search-button{
        width: 72px;
        height: 72px;
        background-color: $color-blue-cornflower;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        margin-top: 24px;
        margin-bottom: 24px;
        

        @media screen and (min-width: $desktop) {
            width: 161px;
            height: 64px;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }

    .search-icon{
        font-size: 24px;

        @media screen and (min-width: $desktop) {
            display: none;
        }
    }

    .search-word{
        display: none;

        @media screen and (min-width: $desktop) {
            display: inline-block;
            font-family: $fonts-din-engschrift;
            font-size: 24px;
            text-transform: uppercase;
        }
    }

    .search-layout__mobile-tabs{
        position: relative;
        width: 100%;
        background-color: $color-black;
        flex-wrap: wrap;

        @media screen and (min-width: $desktop){
            display: none;
        }
    }

    .mobile-search-tabs{
        position: relative;
    }

    .mobile-tabs__dropdown-toggle{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80px;
        text-transform: uppercase;
        font-family: $fonts-din-engschrift;
        font-size: 24px;
        background-color: $color-black;
        color: $color-white;
        border: none;

        @media screen and (min-width: $desktop){
            display: none;
        }
    }

    .mobile-tabs__dropdown-menu{
        display: flex;
        position: absolute;
        z-index: 5;
        flex-wrap: wrap;
        width: 100%;
        background-color: $color-black;
    }

    .mobile-tabs__dropdown-item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        height: 80px;
        font-family: $fonts-din-engschrift;
        font-size: 24px;
        color: white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover{
            background-color: $color-black-mine_shaft;
            color: $color-blue-cornflower;
        }
    }

    .mobile-search-tabs .mobile-tabs__dropdown-menu{
        position: relative;
        height: 0;
        overflow: hidden;
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        padding: 0 20px;
        
    }

    .mobile-search-tabs.active .mobile-tabs__dropdown-menu{
        height: 240px;
    }

    .mobile-search-tabs .search-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(0deg);
    }

    .mobile-search-tabs.active .search-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(180deg);
    }

    .search-facet-count{
        font-family: $fonts-default;
        padding-left: 5px;
        font-size: 18px;
        font-weight: 300;
        position: relative;
        top: 2px;
    }

    .search-arrow{
        color: white;
        margin-left: 18px;
        font-size: 20px;
    }

// COURSES & UNITS NAV DESKTOP
    .search-tabs{
        display: none;

        @media screen and (min-width: $desktop){
            display: block;
            position: relative;
            margin: 0 !important;
            padding: 0;
            background-color: $color-black;
            font-stretch: condensed;
        }
    }

    .search-tabs__list{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        list-style-type: none;
        margin: 0 auto;
        width: 100%;
        height: auto;
        background-color: $color-black;
        max-width: 1410px;

        @media screen and (min-width: $desktop) {
            padding: 0;
        }
    }

    .search-tab{
        @media screen and (min-width: $desktop) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 0;
        }
        
    }

    .search-tab__link{
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-white;
        text-decoration: none;
        margin: 0;
        padding: 0;
        width: 100%;
        border-bottom: 8px black solid;
        height: 88px;
        padding-top: 10px;

        &:hover{
            background-color: $color-black-mine_shaft;
            border-bottom: 8px white solid;
        }
    }

    .search-tab__active{
        color: $color-blue-cornflower;
        border-bottom: 8px $color-blue-cornflower solid;
    }

    .search-tab__link.search-tab__active:hover{
        color: $color-blue-cornflower;
        border-bottom: 8px $color-blue-cornflower solid !important;
        background-color: black;
    }

    .search-tab__heading{
        font-size: 32px;
    }

    .search-tab__search-count{
        display: inline-block;
        position: relative;
        // top: -2px;
        font-size: 18px;
        padding-left: 8px;
        margin: 0 !important;
    }

// SEARCH RESULTS CONTAINER
    .search-layout__search-top{
        display: flex;
        flex-wrap: wrap;
        // position: relative;
        position: -webkit-sticky;
        position: sticky; 
        top: -5px;
        z-index: 5;
        background-color: #eee;

        @media screen and (min-width: $desktop) {
            flex-direction: column-reverse;
            top: unset;
            position: relative;
        }
    }

// SEARCH RESULTS MESSAGE
    .search-results-section{
        
        
        @media screen and (min-width: $desktop){
            margin: 0 auto;
        }
    }

    .search-layout__search-count{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 16px 0;

        @media screen and (min-width: $desktop){
            max-width: 1410px;
            padding: 24px 0;
            margin: 0 auto; 
        }
    }

    .search-count-container{
        font-size: 14px;  
        padding: 0 20px;
        color: $color-grey-light;
        width: 100%;
        line-height: 24px;

        @media screen and (min-width: $desktop){
            font-size: 16px;
            padding: 0 16px;
            margin: 0 auto;
            
        }

        @media screen and (min-width: 1410px) {
            padding: 0;
        }

    }

// SEARCH FILTER MOBILE
    .search-filter{
        width: 100%;
    }
    
    .search-filter__mobile{
        display: grid;
        grid-template-columns: 5fr 8fr;
        width: 100%;
        padding: 0 10px;
        gap: 10px;
        
        @media screen and (min-width: $desktop){
            display: none;
        }
    }

    .search-filter__toggle{
        // margin: 10px;
        // height: 64px;
        position: relative;

        @media screen and (min-width: $desktop) {
            height: 64px;
        }
    }

    .search-filter__button{
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        background-color: $color-white;
        color: $color-black-mine_shaft;
        border: 1px $color-black solid;
    }

    .search-filter__icon{
        margin-right: 10px;
    }

    .search-filter__sort-select{
        width: 100%;
        padding: 10px;
        height: 64px;
        color: $color-black-mine_shaft;
        border: 1px $color-black-mine_shaft solid;
        background-color: white;
    }

    .search-filter__highlight{
        color: $color-blue-cornflower;
    }

    .search-filter__parameters{
        position: absolute;
        background-color: $color-white;
        width: 100%;
        padding: 0 16px;
        padding-bottom: 10px;
        z-index: 100 !important;
    }

    .search-filter__close{
        display: grid;
        grid-template-columns: 5fr 8fr;
        max-width: 376px;
        margin: 0;

        @media screen and (min-width: $desktop) {
            margin: 0;
        }
    }

    .search-filter__close-button{
        display: flex;
        position: relative;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        width: 100%;
        margin: 10px 0;
        height: 64px;
        padding: 0 5px;
        border: none;
        background-color: $color-grey-gallery;
        font-weight: 600;
        
    }

    .search-filter__close-icon{
        margin-left: 10px;
    }

    .search-filter__checkbox{
        background-color: $color-white;
        width: 100%;
    }

    // RADIO BUTTONS

    // .radio__group:nth-child(1){
    //     width: 100%;
    //     display: grid;
    //     grid-template-columns: 4fr 8fr;
    //     height: 64px;
    //     margin-bottom: 8px;
    //     position: relative;
    // }

    // .radio__group:nth-child(2){
    //     width: 100%;
    //     display: grid;
    //     grid-template-columns: 6fr 8fr;
    //     height: 64px;
    //     margin-bottom: 8px;
    // }

    // .radio__group{
    //     visibility: hidden;
    // }
    
    .radio-input{
        position: absolute; 
        opacity: 0;
    }

    .radio-selector{
        width: 1em;
        height: 1em;
        border: 1px solid $color-black-mine_shaft;
        border-radius: 50%;
        padding: 2px;
    }

    .radio-selector::after{
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background-color: $color-black-mine_shaft;
        border-radius: 50%;
        transform: scale(0);
    }

    .radio-input:checked + .radio-selector::after{
        transform: scale(1);
    }

    .radio__links{
        border: 1px $color-black solid;
        display: flex;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        padding: 0 20px;
        background-color: initial;
        color: $color-black-mine_shaft;
        text-decoration: none;
        width: 100%;
        height: 100%;

        @media screen and (min-width: $desktop) {
            justify-content: center;
            padding: 0 16px;
        }
        
    }

    .radio__links.selected-link{
        background-color: #dff3fb;
    }

    .radio__circle{
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
    }

    .radio__circle::before{
        content: "";
        width: 1em;
        height: 1em;
        border: 1px black solid;
        border-radius: 50%;
        color: $color-black-mine_shaft;
    }

    .radio__circle-selected{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .radio__circle-selected::before{
        content: "";
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        border: 1px $color-black-mine_shaft solid;
        background-color: $color-black-mine_shaft;
        position: absolute;
        transform: scale(0);

        @media screen and (min-width: $desktop) {
            width: 0.5rem;
            height: 0.5rem;
        }
    }

    .radio__links.selected-link .radio__circle-selected::before{
        transform: scale(1);
    }

    .search-filter__containers{
        border: 1px #8a8c8a solid;
        margin-bottom: 10px;
        width: 100%;
    }

    .search-filter__containers.active{
        border: 1px #8a8c8a solid;
        @media screen and (min-width: $desktop){
            border: 0;
        }
        
    }

    .search-filter__dropdown-menu{
        .search-filter__containers.active{
            border: 1px black solid;
            @media screen and (min-width: $desktop){
                border: 1px black solid;
            }
            
        }
    }

    .desktop-containers{
        @media screen and (min-width: $desktop) {
            border: 0;
        }
    }

    .search-filter__categories{
        width: 100%;
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 64px;        
        border: none;
        background-color: $color-white;
    }

    .categories__title{
        display: flex;
        align-items: center;
    }

    .categories__icon{
        width: 16px;
        margin-right: 8px;

        @media screen and (min-width: $desktop) {
            margin-right: 10px;
        }
    }

    .search-filter__items{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        padding-bottom: 20px;
        
        label{
            padding-left: 10px;
            cursor: pointer;
        }

        @media screen and (min-width: $desktop) {
            padding-bottom: 16px;
        
        label{
            padding-left: 8px;
            cursor: pointer;
        }
        }
    }
    
    .search-filter__containers .search-filter__selections{
        position: relative;
        height: 0;
        overflow: hidden;
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        padding: 0 16px;
        
    }

    .search-filter__containers.active .search-filter__selections{
        height: auto;
        padding: 8px 16px;

        @media screen and (min-width: $desktop) {
            padding: 16px;
        }
    }

    .search-filter__containers .accordion-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(0deg);
    }

    .search-filter__containers.active .accordion-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(180deg);
    }

    .checkbox-selector{
        width: 1em;
        height: 1em;
        border: 1px $color-black-mine_shaft solid;
        border-radius: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background 0.15s, border-color 0.15s;
        
        @media screen and (min-width:$desktop) {
            margin-right: 5px;
        }
    }

    .search-filter__items{
        input[type=checkbox] {
            -webkit-appearance: none;
            appearance: none;
            background: #fff; /* this is needed for iOS. Otherwise the background just becomes black. */
            border: 1px solid #333;
            height: 1em;
            width: 1em;
            display: inline-block;
            vertical-align: text-bottom;
            position: relative;
            
            &[disabled],
            &[aria-disabled=true] {
                opacity: .4;
            }
            
            &::before {
                content: '';
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                top: 0;
            }
        }
    
        input[type=checkbox]:checked::before {
            border-right: 2px solid white;
            border-bottom: 2px solid white;
            height: 0.6rem;
            width: 0.3rem;
            transform: rotate(45deg) translateY(-10%) translateX(-20%);
        }

        input[type=checkbox]:checked {
            background-color: black;
        }
    }


    .search-filter__buttons{
        width: 100%;
        margin: 5px 0;
        min-height: 64px;

        @media screen {
            min-height: unset;
        }
    }

    .apply-filter-button{
        
        border: 2px $color-aubergine solid;
        margin: 8px 0;
        padding: 16px 0;
        font-size: 1.5rem;
        line-height: 32px;
        font-family: $font-engschrift-pro;
        text-transform: uppercase;
        background-color: $color-aubergine;
        color: white;
        
        &:hover{
            background-color: white;
            color: $color-aubergine;
        }   

        @media screen and (min-width: $desktop) {
            padding: 0;
            width: 107px;
            font-size: 20px;
        }
    }

    .cancel-button{
        border: none;
        background: $color-white;
        font-weight: 600;
        font-size: 16px;
        padding: 20px 0;
        
        @media screen and (min-width: $desktop) {
            padding: 0;
        }
    }

// SEARCH FILTER DESKTOP
    .search-filter__desktop{
        display: none;

        @media screen and (min-width: $desktop) {
            display: block;
            background-color: $color-white;
        }
    }

    .search-filter__desktop-filter-buttons{
        background-color: $color-white;
        border: none;
    }

    .desktop-checkbox__field{
        border: 1px $color-black solid;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background-color: initial;

        label{
            cursor: pointer;
        }
        
    }

    .desktop-checkbox__field.selected-field{
        background-color: #dff3fb;
    }

    .desktop-radio-input {
        display: none;
    }

    .desktop-radio-input + *::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 1rem;
        height: 1rem;
        margin-right: 10px;
        border-radius: 50%;
        border-style: solid;
        border-width: 0.1rem;
        border-color: $color-black;
    }
    
    .desktop-radio-input:checked + *::before {
        background: radial-gradient($color-black 0%, $color-black  40%, transparent 50%, transparent);
        border-color: $color-black;
    }

    .search-filter__desktop-categories{
        border: 1px $color-black solid;
        height: 64px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    .desktop-categories__icon{
        margin-right: 10px;
    }

    .scroll-selection{
        padding: 20px 0 20px 20px;
    }

    .search-filter__desktop-categories.selected-dropdown{
        background-color: $color-black;
        color: $color-white;
    }

    .search-filter__desktop-items{
        
        p{
            padding-bottom: 20px;
        }

        label{
            padding-left: 5px;
        }
    }

    .search-filter__desktop-categories .accordion-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(0deg);
    }

    .search-filter__desktop-categories.selected-dropdown .accordion-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(180deg);
    }

    // CHECKBOX DESKTOP

    .search-filter__scroll{
        height: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        padding-right: 10px;
        margin-bottom: 20px
    }


// SEARCH DISPLAY RESULTS CARDS SECTION
    .search-display{
        display: flex;
        flex-direction: column-reverse;
        padding: 0 16px;
        
        @media screen and (min-width: $desktop) {
            display: grid;
            grid-template-columns: 2fr 7fr;
            max-width: 1440px;
            margin: 0 auto;
            width: 100%;
            gap: 32px;

        }

    }

// LIST / DETAIL
    .search-display__display-switch{
        display: none;

        @media screen and (min-width: $desktop) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }

    .display-switch__list-detail{
        display: none;

        @media screen and (min-width: $desktop) {
            display: grid;
            grid-template-columns: 83px 101px;
            height: 64px;
            visibility: hidden;
        }
    }

    .list-detail__box{
        // border: 1px blue solid;
        border: 1px #8a8c8e solid;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .list-box{
        border-right: 0;
        
    }

    .list-box.selected-box{
        background-color: #dff3fb;
    }

    .detail-box.selected-box{
        background-color: #dff3fb;
    }


    .switch-icon{
        margin-right: 5px;
    }

    .display-switch-relevance{
        // border: 1px green solid;
        width: 308px;
        position: relative;
        // z-index: 2;
    }

    .relevance-button{
        width: 100%;
        // height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 16px;
        background-color: $color-white;
        border: 1px #8a8c8e solid;
        // border: 0;

        @media screen and (min-width: $desktop) {
            font-size: 18px;
        }
    }

    .relevance-button.active{
        border-bottom: 1px white solid;
    }

    .relevance-button .relevance-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(0deg);
    }

    .relevance-button.active .relevance-arrow{
        transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
        transform: rotate(180deg);
    }


    .relevance-span{
        color: #0c71ac;
        font-weight: 600;
    }

    .relevance-list{
        position: absolute;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        background-color: $color-white;
        display: none;
        line-height: 28px;

        @media screen and (min-width: $desktop) {
            padding: 0;
            font-size: 18px;
        }     
    }

    .relevance-list.active{
        display: flex;
        border: 1px #8a8c8e solid;   
        border-top: 0;
        z-index: 1;
    }

    .relevance-list-items{
        width: 100%;
        line-height: 28px;
        
        display: flex;
        align-items: center;
        margin: 0;

        a{
            padding: 18px;
            color: $color-black-mine_shaft;
            text-decoration: none;

            &:visited{
                color: $color-black-mine_shaft;
                text-decoration: none;
            }
    
            &:hover{
                color: #0c71ac;
                text-decoration: underline;
            }

            &:active{
                color: $color-aubergine;
            }
        }

        &:hover{
            color: #0c71ac;
        }

        &:active{
            color: $color-aubergine;
        }

    }

    .relevance-list-items.focused{
        background-color: $color-new-blue;
    }

    .relevance-list-items.focused{
        background-color: $color-new-blue;
        
        a:hover{
            color: $color-black-mine_shaft;
        }
    }

// SHOW NEXT INTAKE ONLY
    .search-display__intake{
        padding: 24px 16px 20px 16px;

        @media screen and (min-width: $desktop) {
            padding: 24px 0 20px 0;
        }
    }

    .search-display__intake{
        input{
            margin-right: 5px;
        }

        label{
            @media screen and (min-width:$desktop) {
                font-size: 18px;
            }
        }
    }

// MOBILE SEARCH PILLS
.mobile-search-results__filter-pills{
    display: flex;
    gap: 15px 8px;
    flex-wrap: wrap;

    .filter-pills{
        padding: 14px 16px;
        font-size: 14px;
    }
    
    .filter-pills__clear{
        width: 100%;
        margin-bottom: 8px;
    }

    @media screen and (min-width: $desktop){
        display: none;
    }
}

// SEARCH FILTER PILLS
    .search-results__filter-pills{
    display: none;
    font-family: "source-sans-pro", Arial, sans-serif;
    
        @media screen and (min-width: $desktop){
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            // margin: 10px 0 20px 0;
            padding: 4px 0;
        }
    }

    .filter-pills{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 16px;
        line-height: 24px;
        height: 48px;
        background-color: #f7f7f7;
        border-radius: 24px;
        // padding: 14px 16px;
        

        a{
            text-decoration: none;
            color: $color-black-mine_shaft;
            
            &:visited{
                color: $color-aubergine;
            }
        }

        @media screen and (min-width: $desktop){
            padding: 4px 16px;
            background-color: $color-white-alabaster;
            height: unset;
        }
    }

    .pills-delete-button{
        border: none;
        background-color: initial;
    }

    .delete-icon{
        font-size: 12px;
        margin-left: 9px;

        @media screen and (min-width: $desktop){
            font-size: 15px;
            // margin-top: 5px;
            position: relative;
            top: 1px;
        }
    }

    .filter-pills__clear{

        border: none;
        background-color: $color-white;
        width: 58px;
        height: 64px;
        color: $color-black;
        font-weight: 600;
        font-size: 16px;
        margin-top: -7px;
       

        @media screen and (min-width: $desktop){
            text-decoration: underline;
            width: 58px;
            height: 24px;
            color: $color-aubergine;
            background-color: initial;
            margin: auto 0;
            margin-left: 24px;
        }
    }

// INDIVIDUAL CARDS

    .results-card{
        background-color: $color-white;
        // padding: 16px 16px 8px 16px;
        margin: 8px 0;
        // cursor: pointer;

        &:hover{
            background-color: $color-new-blue;
        }

        @media screen and (min-width: $desktop){
            // padding: 16px 24px;
            margin: 10px 0;
            // max-height: 253px;
        }
    }

    .results-card:nth-child(1){
        margin-top: 0;
        
        @media screen and (min-width: $desktop){
            margin: 10px 0;
        }
    }

    .card-desktop{
        padding: 16px 16px 8px 16px;
        @media screen and (min-width: $desktop){
            padding: 16px 24px;
            display: grid;
            grid-template-columns: 9fr 1fr;
        }
    }

    .card-main{
        display: block;
        @media screen and (min-width: $desktop){
            // display: flex;
            display: none;
        } 
    }

    .card-details-desktop{
        display: none;

        @media screen and (min-width: $desktop) {
            display: block;
        }
    }

    .card-details-desktop{
        display: none;

        @media screen and (min-width: $desktop) {
            display: block;
        }
    }

    .results-card:hover .results-card__details{
        border-color: $color-aubergine;
    }

    .results-card:hover .card-subtitle{
        color: $color-aubergine;
    }

    .results-card:hover .course-description{
        color: $color-aubergine;
    }

    .results-card:hover .course-time__heading{
        color: $color-aubergine;
    }

    .results-card:hover .course-time__info{
        color: $color-aubergine;
    }

    .results-card:hover .results-card__short-description{
        color: $color-aubergine;
    }
    
    .results-card:hover .card-link{
        text-decoration: underline;
    }


    .results-card__title{
        
        margin: 0;
        line-height: 28px;

        @media screen and (min-width: $desktop) {
            font-size: 20px;
        }

        a{
            text-decoration: none;
            color: #262626;
        }
    }

    .card-link{
        color: $color-black-mine_shaft;
        text-decoration: none;

        &:visited{
            color: $color-aubergine;
            text-decoration: underline;
        }

        &:active{
            color: $color-aubergine;
            text-decoration: underline;
        }

        &:focus{
            color: $color-aubergine;
        }
    }

    .results-card__subtitle{
        margin: 0;
    }

    .card-subtitle{
        color: $color-grey-light;
        font-size: 14px;
    }

    .results-card__details{
        border-top: 1px $color-grey-dusty_gray solid;
        font-size: 16px;
        margin-top: 16px;

        @media screen and (min-width: $desktop) {
            border-top: none;
            margin-top: 0;
        }
    }

    .course-info{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .course-details{
        padding: 16px;
        display: flex;  

        @media screen and (min-width: $desktop) {
            padding: 12px 16px 0 16px;
        }
    }

    .course-description{
        width: 100%;
        padding-bottom: 8px;
        line-height: 28px;
        

        @media screen and (min-width: $desktop) {
            border-bottom: 1px $color-grey-dusty_gray solid;
            font-size: 18px;
            padding-bottom: 24px;
        }
    }

    .course-icon{
        margin-right: 13px;
        font-size: 23px !important;
        height: 23px;

    }

    .course-time__heading{
        font-weight: 600;
        font-size: 16px;
        color: #262626;

        @media screen and (min-width: $desktop) {
            font-size: 18px;
        }
    }

    .course-time__info{
        font-size: 16px;
        color: #262626;

        @media screen and (min-width: $desktop) {
            font-size: 18px;
        }
    }

    .results-card__clock-icon{
        margin-top: 2px;
        
    }

    .results-card__calendar-icon{
        margin-top: 2px;
    }

    .results-card__dollar-icon{
        margin-top: 1px;
    }

    .card-icons{
            font-size: 23px;
            height: 23px;
    }

    .results-card__save{
        display: flex;
        // display: none;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: 600;
        border: 1px red solid;

    }

    .results-card__save-button{
        width: 130px;
        max-width: 130px;
        padding: 20px 32px;
        font-size: 16px;
        height: 64px;
        border: none;
        background-color: $color-white;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: $desktop) {
            padding: 18px 32px;
            font-size: 18px;
            margin-bottom: 2px;
            justify-content: space-between;
            width: 134px;
            max-width: 134px;
        }

    }

    .results-card__save-button:active{
        background-color: $color-aubergine !important;
        color: $color-white;
        // transition: ease-in-out 0.2s;
    }

    // .results-card__save-button.saved{
    //     background-color: $color-aubergine !important;
    //     color: $color-white;
    // }

    .results-card__save-icon{
        font-size: 24px;
        margin-right: 8px;

        @media screen and (min-width: $desktop) {
            position: relative;
            top: -2px;
        }
    }

    .regular-heart{
        color: $color-aubergine;
    }

    .solid-heart{
        color: $color-aubergine;
    }

// SEARCH RELATED LINKS
    .search-related{
        margin-top: 50px;
        padding: 16px 15px;
        padding-bottom: 88px;

        @media screen and (min-width: $desktop) {
            margin-top: 104px;
            padding: 16px;
            padding-bottom: 120px;
        }

    }

    .search-related__container{
        margin: 0 auto;
        width: 100%;

        @media screen and (min-width: $tablet) {
            max-width: 1410px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .search-related__heading{
        width: 100%;
        max-width: 1410px;
        margin: 0 auto;
        text-transform: none;
        margin-bottom: 24px;
    }

    .search-related__list{
        list-style-type: none;
        padding: 0;
    }

    .list-item{
        margin: 16px 0;
        padding: 16px 0;

        @media screen and (min-width: $desktop) {
            margin: 8px 0;
            padding: 8px 0;
        }
    }

    .related__list-links{
        color: $color-black-mine_shaft;
        text-decoration: none;

        &:hover{
            color: $color-blue-venice_blue;
            text-decoration: underline;
        }
    }

    // SEARCH DISPLAY RESULTS CARDS SECTION

    .search-display__aside{
        padding: 50px 0;

        @media screen and (min-width: $desktop) {
            padding: 0;

        }
    }

    .aside__ad{
        width: 100%;
        height: auto;
        // border: 1px blue dashed;
        margin: 20px 0;
        object-fit: cover;
        display: flex;

        @media screen and (min-width: $desktop) {
            margin: 0 0 24px 0;
            height: 181px;
        }
    }

    .aside__ad-image{
        margin: 0 auto;
    }

    .aside__buttons{
        width: 100%;
        height: 64px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        background-color: $color-black;
        color: $color-white;
        font-weight: 600;
        font-size: 18px;
        border: none;
    }

    .aside__saved-items{
        display: none;

        @media screen and (min-width: $desktop) {
            display: flex;
        }
    }

    .aside__icon{
        margin: 0 18px;
        font-size: 20px;
        min-width: 20px;
    }

    .aside__saved-items:hover{
        text-decoration: underline;
    }

    .aside__glossary:hover{
        text-decoration: underline;
    }

    // STICKY BOTTOM SAVE BUTTON

    .search-display__sticky-save{
        display: flex;
        justify-content: flex-end;
        // grid-template-columns: 5fr 3fr;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
        background: rgba(255, 255, 255, 0.9);
        display: none;

        @media screen and (min-width: $desktop) {
            display: none;
        }
    }

    .sticky-save{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        opacity: 1 !important;
        width: 202px;
        height: 48px;
        border: none;
        margin-right: 16px;
        position: relative;
        padding: 16px 32px;
        font-size: 16px;
        // right: 40px;
    }

    .sticky__icon{
        margin-right: 8px;
        font-size: 20px;
    }

// LOAD MORE / PAGINATION
    .search-display__load{
        display: flex;
        justify-content: center;

        @media screen and (min-width: $desktop) {
            display: none;
        }
    }

    .search-display__load-button{
        font-size: 24px;
        font-family: $fonts-din-engschrift;
        background-color: $color-aubergine;
        color: white;
        border: 2px $color-aubergine solid;
        width: 100%;
        height: 64px;
        margin-top: 30px;

        &:hover{
            background-color: $color-grey-gallery;
            color: #1e1248;
            border: 2px $color-aubergine solid;
        }
    }

    .search-display__pagination{
        
        display: none;

        @media screen and (min-width:$desktop) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        // border: 1px blue solid;
        list-style-type: none;
        gap: 2px;
        }
    }

    .pagination__nav-buttons{
        height: 100%;
        color: $color-aubergine;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        max-height: 40px;

        &:hover{
            color: $color-aubergine;
            background-color: $color-new-blue;
        }
    }

    .pagination__first, .pagination__last{
        width: 40px;
        border: none;
    }

    .first-icon, .last-icon{
        font-size: 10px;
    }

    .pagination__prev, .pagination__next{
        width: 76px;
        background-color: $color-white;
        border: none;
        font-size: 12px;
    }

    .backward-icon, .forward-icon{
        font-size: 10px;
    }

    .backward-icon{
        margin-right: 5px;
    }

    .forward-icon{
        margin-left: 5px;
    }

    .pagination__number{
        width: 41px;
        background-color: $color-white;
        border: none;
    }

    .selected-pagination{
        background-color: $color-aubergine;
        color: $color-white;
    }



// SHORTLIST

    .shortlist{
        display: flex;
        justify-content: flex-end;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;
        background: rgba(0, 0, 0, 0.3);
        
    }

    .shortlist__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .shortlist__heading{
        padding: 0;
        margin: 0;
        margin-top: 20px;
        margin-bottom: 8px;
        font-size: 28px;
        line-height: 36px;

        @media screen and (min-width: $desktop) {
            font-size: 44px;
            margin-bottom: 0;
        }
    }

    .shortlist__close-button{
        width: 20px;
        height: 20px;
        border: none;
        background-color: initial;

        @media screen and (min-width: $desktop) {
            width: 40px;
            height: 40px;
            
        }
    }

    .shortlist__close-button-icon{
        font-size: 22px;
        margin-top: 7px;
        margin-right: 16px;

        @media screen and (min-width: $desktop) {
            font-size: 40px;
            
        }
    }

    .shortlist__saved{
        width: 80%;
        height: 100vh;
        background-color: $color-white;
        padding: 10px 20px;
        

        @media screen and (min-width: $desktop) {
            width: 1172px;
            height: 100vh;
            background-color: $color-white;
            padding: 30px 40px;
            overflow-y: auto;
        }
    }

    .results-card__mobile-scroll{
        overflow-y: auto;
        height: 83vh;

        @media screen and (min-width: $desktop) {
            overflow-y: unset;
        }
    }

    .shortlist__clear{

        display: none;
        @media screen and (min-width: $desktop) {
            background-color: $color-white !important;
            border: none;
            text-decoration: underline;
            margin: 24px 0 16px 0;
            font-weight: 600;

        }
    }
    
    .shortlist__clear-mobile{
            background-color: $color-white !important;
            border: 0;
            // margin: 8px 0;
            font-weight: 600;
            width: 100%;
            display: flex;
            padding: 20px 0;
            justify-content: center;
            align-items: center;

        @media screen and (min-width: $desktop) {
           display: none;
        }
    }
    .shortlist__card{
        background-color: $color-white-alabaster
    }

    .results-card__delete{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: 600;

    }

    .delete-button{
        min-width: 159px;
        height: 64px;
        border: none;
        background-color: initial;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-aubergine;
        font-size: 16px;
        line-height: 24px;
        padding: 20px 32px;

        @media screen and (min-width: $desktop) {
            min-width: 159px;
            font-size: 18px;
            background-color: $color-white;
            font-size: 16px;
            line-height: 24px;
            padding: 18px 32px;
        }

        &:hover{
            background-color: $color-aubergine;
            color: $color-white;
        }

    }

    .results-card__delete-icon{
        margin-right: 10px;
        font-size: 25px;
    }
}

/*-----------------------------------*/ 

// NEW SEARCH FILTER
.search-people__wrapper{
    .search-filter{
        position: relative;
        background-color: #eee;
        z-index: 100 !important;

        @media screen and (min-width: $desktop) {
            height: 100%;
            background-color: white;
            display: flex;
            align-items: center;
            padding: 23px 0;
        }

        .radio__group{
            background-color: white;
        }

        .search-filter__toggle{
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 8px;
            width: 100%;
            padding: 8px 16px;
            // padding: 16px;
            background-color: #eee;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        .search-filter__button{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .search-filter__sort-select{
            display: flex;
            justify-content: center;
            align-items: center;
            appearance: none;
            background-image: url("src/modules/search/assets/chevron-down-solid.svg");
            background-repeat: no-repeat;
            background-size: 15px;
            background-position: calc(100% - 19px) center;
        }

        .search-filter__parameters{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            display: none;

            @media screen and (min-width: $desktop) {
                position: relative !important;
                padding: 0 16px;
                display: flex;
                justify-content: center;
            }
        }

        .search-filter__parameters.active{
            display: block;

            @media screen and (min-width: $desktop) {
                display: flex;
            }
        }

        .search-filter__wrapper{
            @media screen and (min-width: $desktop) {
                max-width: 1410px;
                width: 100%;
                margin: auto;
            }
        }

        // GLOBAL FILTER PARAMETERS
        .search-filter__global-filter-parameters{
            @media screen and (min-width: $desktop){
                display: flex;
                justify-content: space-between;
                position: relative;
                z-index: 5 !important;
            }
            
        }

        // Change desktop version for "radio" select width

        .search-filter__radio-filter{
            // display: flex;
            flex-wrap: wrap;
            display: none;
            

            @media screen and (min-width: $desktop) {
                position: relative;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: unset;
                max-width: 48%;
                gap: 16px;

            }
        }

        .radio__group{
            @media screen and (min-width: $desktop) {
            margin-bottom: 0 !important;

            }
        }

        .radio__group:nth-child(1){
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 2fr;
            height: 64px;
            margin-bottom: 8px;
            position: relative;

            @media screen and (min-width: $desktop) {
                display: flex;
                // grid-template-columns: 117px 190px;
                // grid-template-columns: 1fr 1fr;

            }
        }

        .radio__group:nth-child(2){
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 64px;
            margin-bottom: 8px;

            @media screen and (min-width: $desktop) {
                display: flex;
                // grid-template-columns: 200px 155px;
                // grid-template-columns: 127px 155px;

            }
        }

        .search-filter__resident-info{
            display: none;

            @media screen and (min-width: $desktop) {
                display: block;
                border: 0;
                background-color: white;
            }
        }

        .resident-info{
            font-size: 1.25rem;
        }

        // SEARCH FILTER DESKTOP CATEGORIES TOGGLE
        .search-filter__categories-toggle{
            display: none;
            @media screen and (min-width: $desktop){
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                width: 100%;
                max-width: 100%;
                // SEARCH PAGE MAX WIDTH
                // max-width: 47%;
            }
        }

        .search-filter__categories-toggle-wrapper{
            @media screen and (min-width: $desktop){
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 16px;
                
            }
        }

        .filter-options{

            @media screen and (min-height){
                max-height: 512px;
                overflow-y: auto;
            }
        
        }

        .search-filter__desktop-filter-buttons{
            display: flex;
            justify-content: space-between;
            // Change width of filter toggles
            min-width: 240px;
        }

        .search-filter__close-button{
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            width: 100%;
            margin: 10px 0;
            height: 64px;
            padding: 0 5px;
            border: none;
            background-color: $color-grey-gallery;
            font-weight: 600;

            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        .search-filter__close-icon{
            margin-left: unset;
            margin-right: 8px;
        }

        .radio__group .radio__links:nth-child(2){
            border-left: 0 !important;
        }

        .radio__group:nth-child(1) .radio__links:nth-child(2){
            border-left: 0 !important;

            @media screen and (min-width: $desktop) {
                min-width: 208px; 
            }
            
        }

        .search-filter-buttons__container{
            @media screen and (min-width: $desktop) {
                display: flex;
                width: 100%;
                justify-content: flex-end;
                align-items: center;
                padding: 16px;
            }
        }

        .form-button-group{
            @media screen and (min-width: $desktop) {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                height: auto;
                gap: 16px;
            }
        }

        .search-filter__buttons{
            @media screen and (min-width: $desktop) {
                margin: 0;
                height: 48px;
            }
        }

        .cancel-button{
            @media screen and (min-width: $desktop) {
                width: 70px;
            }
        }

        .desktop-categories{
            @media screen and (min-width: $desktop) {
                display: none;
            }
        }

        .search-filter__checkbox-options{
            position: relative;

            @media screen and (min-width: $desktop) {
                display: none;
                position: absolute;
                border: 1px #8a8c8a solid;
                top: 72px;
                right: 0;
                background-color: white;
                z-index: 5 !important;
                box-shadow: rgba(138, 140, 142, 0.35) 0px 5px 15px;
            }
        }

        .search-filter__checkbox-options.active{
            display: block;
        }

        .search-filter__selections-title{
            display: none;
            @media screen and (min-width: $desktop) {
                display: inline-block;
                width: 100%;
                margin-top: 4px;
                margin-bottom: 22px;
            }
        }

        .desktop-containers{
            display: block;
            @media screen and (min-width: $desktop) {
                // border: 0;
                display: none;
            }

            &.active{
                @media screen and (min-width: $desktop) {
                    display: block;
                }
            }
        }

        // MORE SEARCH FILTERS
        .more-search-filter__wrapper{

            display: block;

            @media screen and (min-width: $desktop) {
                display: none;
                padding: 20px 16px 16px 16px;
            }
        }

        .more-search-filter__wrapper.active{
            display: block
            ;
            @media screen and (min-width: $desktop) {
                max-height: 512px;
                overflow-y: auto;
            }  
        }

        .search-filter__desktop-filter-buttons.active{
            background-color: $color-black;
            color: $color-white;
        }

        .search-filter__desktop-filter-buttons .accordion-arrow{
            transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
            transform: rotate(0deg);
        }

        .search-filter__desktop-filter-buttons.active .accordion-arrow{
            transition: 0.5s cubic-bezier(0.075, 0.82, 0.4, 1);
            transform: rotate(180deg);
        }
        

    }

    // PEOPLE SEARCH SPECIFIC RESULTS CARD
    .card-subtitle{
        display: flex;
        flex-wrap: wrap;
        gap: 0 8px;
        margin-bottom: 8px;
    }
    
    .people-contact-info{
        color: #6e6e6e;
        font-size: 0.875rem;
    }

    .results-card__short-description{
        padding-bottom: 8px;
    }

    .people-info{
        margin: 0 8px;
    }

    .results-card__staff-image{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: 600;
    }

    .staff-image{
        width: 100px;
        height: 100px;
        background-size: cover;
        border-radius: 50%;
        background-position: center center;
    }

    .staff-image1{
        background-image: url("src/modules/assets/staff/alex_parker2.jpeg");
    }

    .staff-image2{
        background-image: url("src/modules/assets/staff/vasso-apostolopoulos.jpeg");
    }   

    .people-expertise{
        padding: 8px 16px 16px 16px;
        font-size: 0.875rem;
        color: #6e6e6e;
        background-color: rgba(91,194,231,0.1);;

        @media screen and (min-width: $desktop){
            padding: 16px 24px;
        }
    }

    .expertise-text{
        padding-right: 8px;
    }

    .results-card:hover .people-contact-info{
        color: #1e1248;
    }

    .results-card:hover .people-expertise{
        color: #1e1248;
    }
}